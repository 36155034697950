import { useRef, useState } from 'react';

import { Button, CTA, HamburgerMenu, Text } from '@/components/atomic/nuclei';
import { DISPATCH_CTA_STATUS } from '@/config/bid';
import { staticMediaStoreBaseURL } from '@/config/common';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { getWebQuotePageURL } from '@/services/hostPortal.service';
import {
  dispatchCTAStatusLabelMap,
  getDispatchCTALabel
} from '@/services/tender.service';

const ReOrderCartItemsCTA = ({
  checkoutReadyItems,
  pricedItems,
  setPricedItems,
  setResequenceCartItems,
  showCartItemListHeaderCTAs,
  updateCartItemSortOrder
}) => (
  <div className='flex gap-4 justify-between mb-4'>
    <Text {...{ className: 'text-lg font-medium', content: 'Item List' }} />
    {showCartItemListHeaderCTAs && (
      <div className='flex gap-2'>
        <CTA
          {...{
            buttonColor: 'bg-gray',
            className: ' h-6 text-sm font-medium px-4 py-1.5',
            label: 'Cancel',
            onClick: () => {
              setResequenceCartItems(false);
              setPricedItems(checkoutReadyItems);
            }
          }}
        />
        <CTA
          {...{
            buttonColor: 'bg-orange',
            className:
              'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white px-4 py-1.5 rounded-lg text-sm font-medium',
            label: 'Save',
            onClick: () => {
              const cartItemListWithRevisedOrder = pricedItems.map(
                ({ id }, index) => ({ id, sortOrder: index + 1 })
              );
              updateCartItemSortOrder(cartItemListWithRevisedOrder);
              setResequenceCartItems(false);
            }
          }}
        />
      </div>
    )}
  </div>
);

const CartItemListHeaderCTAs = ({
  cartId,
  checkoutReadyItems,
  createNewTenderAndPublishBids,
  isCartEditable,
  isDefault,
  menuOptions,
  pricedItems,
  resequenceCartItems,
  setPricedItems,
  setResequenceCartItems,
  tenders,
  updateCartItemSortOrder
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const hamburgerMenuRef = useRef(null);

  const showCartItemListHeaderCTAs = isCartEditable && pricedItems.length > 0;

  useOnClickOutside(hamburgerMenuRef, () => setShowMenu(false));

  if (resequenceCartItems) {
    return (
      <ReOrderCartItemsCTA
        {...{
          setResequenceCartItems,
          setPricedItems,
          checkoutReadyItems,
          pricedItems,
          showCartItemListHeaderCTAs,
          updateCartItemSortOrder
        }}
      />
    );
  }

  const dispatchCTALabel = getDispatchCTALabel({ tenders });

  return (
    <div
      className='flex gap-4 justify-between mb-4'
      ref={hamburgerMenuRef}
    >
      <Text {...{ content: 'Item List', className: 'text-lg font-medium' }} />
      {showCartItemListHeaderCTAs && (
        <div className='flex gap-4'>
          <Button
            {...{
              className:
                'bg-white text-brand px-4 py-1 border border-orange rounded-lg text-sm font-medium h-8',
              iconGroupClassName: 'gap-0',
              iconHeight: 15,
              iconPosition: 'Left',
              iconURL: `${staticMediaStoreBaseURL}/icons/view.svg`,
              iconWidth: 15,
              label: 'Web Quote',
              onClick: () => {
                window.open(getWebQuotePageURL({ cartId }), '_blank');
              },
              width: 'w-auto'
            }}
          />
          {!isDefault && (
            <Button
              {...{
                className:
                  'bg-white text-brand px-4 py-1 border border-orange rounded-lg text-sm font-medium h-8',
                iconGroupClassName: 'gap-0',
                iconHeight: 17,
                iconPosition: 'Left',
                iconURL: `${staticMediaStoreBaseURL}/icons/send-icon.svg`,
                iconWidth: 17,
                label: dispatchCTAStatusLabelMap[dispatchCTALabel],
                onClick: () => {
                  if (
                    dispatchCTALabel !==
                    DISPATCH_CTA_STATUS.REQUESTING_BID.value
                  ) {
                    createNewTenderAndPublishBids({
                      cartId,
                      firstTender:
                        dispatchCTALabel ===
                        DISPATCH_CTA_STATUS.REQUEST_BID.value
                    });
                  }
                },
                width: 'w-auto'
              }}
            />
          )}
          <HamburgerMenu
            {...{
              menuOptions,
              setShowMenu,
              showMenu
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CartItemListHeaderCTAs;
