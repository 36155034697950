import Image from 'next/image';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Carousel } from 'react-responsive-carousel';

import { ImageCarouselModal } from '@/components/atomic/nuclei';
import { getImageURL, getRankedImages } from '@/helpers/carousel';
import { noProductImageURL } from '@/services/plannerPortal.service';

const onImageClick = ({ image, openImageModal }) => {
  openImageModal(image);
};

const CustomThumbnail = ({ image, openImageModal }) => {
  const imageUrl = getImageURL(image);
  return (
    <div
      onClick={() =>
        onImageClick({
          image,
          openImageModal
        })
      }
    >
      <picture className='flex items-center'>
        <source
          data-srcset={image.url}
          type='image/jpg'
        />
        <Image
          alt='image thumbnail'
          height={0}
          src={imageUrl}
          style={{ hight: 50, width: 76 }}
          width={0}
        />
      </picture>
    </div>
  );
};

const CustomThumbnails = ({ images = [], openImageModal }) =>
  images.map((image, index) => (
    <CustomThumbnail
      key={index}
      {...{
        image,
        openImageModal
      }}
    />
  ));

const CarouselImage = ({ containerStyle, image, openImageModal, style }) => {
  const [imageURL, setImageURL] = useState(getImageURL(image));

  return (
    <div
      className={`relative overflow-hidden ${containerStyle}`}
      key={`${image.url}`}
      onClick={() =>
        onImageClick({
          image,
          openImageModal
        })
      }
    >
      <Image
        {...{
          alt: 'image',
          className: style,
          fill: true,
          onError: () => setImageURL(noProductImageURL),
          src: imageURL,
          style: { objectFit: 'cover' },
          unoptimized: true
        }}
      />
    </div>
  );
};

const ImageCarousel = ({
  containerStyle,
  images: imagesRaw = [],
  isThumbnail = true,
  style
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [slidePosition, setSlidePosition] = useState(0);

  const [modalImage, setModalImage] = useState(false);

  const openImageModal = (image) => {
    setModalImage(image);
  };

  const images = getRankedImages(imagesRaw);
  return (
    <div
      className='relative'
      onMouseEnter={() => {
        setIsFocus(true);
        setSlidePosition(1);
      }}
      onMouseLeave={() => {
        setIsFocus(false);
        setSlidePosition(0);
      }}
    >
      <Carousel
        {...{
          autoPlay: isFocus,
          infiniteLoop: true,
          interval: 5000,
          renderThumbs: () =>
            isThumbnail && CustomThumbnails({ images, openImageModal }),
          selectedItem: slidePosition,
          showArrows: false,
          showIndicators: images.length > 1,
          showStatus: false,
          showThumbs: !isMobile,
          transitionTime: 800
        }}
      >
        {images.map((image, index) => (
          <CarouselImage
            key={index}
            {...{
              containerStyle,
              image,
              openImageModal,
              style
            }}
          />
        ))}
      </Carousel>
      {modalImage && (
        <ImageCarouselModal
          {...{
            images,
            modalImage,
            openImageModal
          }}
        />
      )}
    </div>
  );
};

export default ImageCarousel;

ImageCarousel.propTypes = {
  containerStyle: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
  isThumbnail: PropTypes.bool,
  style: PropTypes.string
};
