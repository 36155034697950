import Image from 'next/image';
import { PropTypes } from 'prop-types';

import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const EventOrderDetails = ({ userEventDetails }) => {
  const {
    userEventOrderDetailsBanner: { eventName, trnNumber },
    userEventOrderDetailsBody
  } = userEventDetails;
  return (
    <div className='flex flex-col gap-3 bg-lightgray border-b border-r rounded-b-lg border-l border-platinum text-rich-black md:p-6 p-4'>
      <TextKeyValuePair
        {...{
          className: 'flex-col md:w-2/4',
          label: eventName.value,
          labelClass: 'text-base md:text-xl font-medium text-rich-black',
          spaceTop: 'gap-1 md:gap-2',
          value: trnNumber.value,
          valueClassName: 'text-xs md:text-base text-dim-gray font-medium'
        }}
      />
      <div className='flex flex-col md:flex-row justify-between'>
        <div className='grid grid-cols-1 md:grid-cols-4 md:grid-rows-2 gap-2 mt-2 md:mt-0'>
          {userEventOrderDetailsBody.map(
            ({ alt, className, icon, sortOrder, value }) => (
              <div
                key={sortOrder}
                className={`flex gap-3 md:gap-2 md:w-96 ${className}`}
              >
                <div className='flex'>
                  <Image
                    alt={alt}
                    height={0}
                    src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                    style={{ height: 20, width: 20 }}
                    width={0}
                  />
                </div>
                <Text
                  {...{
                    className:
                      'flex self-center text-sm md:text-base font-light max-w-52 truncate',
                    content: value
                  }}
                />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default EventOrderDetails;

EventOrderDetails.propTypes = {
  userEventDetails: PropTypes.object.isRequired
};
