import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';

import {
  CHATBOT_CONFIG,
  CHATBOT_ROOT_CONTAINER_DIV_ID,
  enableSendbirdChatBotInEnv,
  sendbirdChatbotEnabledPagePathList
} from '@/config/sendbird';
import { isBrowser } from '@/lib/utils';

const SendbirdChatBot = () => {
  const router = useRouter();

  const enableSendbirdChatBotOnPage =
    sendbirdChatbotEnabledPagePathList.includes(router.pathname);

  const enableSendbirdChatBot =
    enableSendbirdChatBotOnPage && enableSendbirdChatBotInEnv;

  const [botStyle, setBotStyle] = useState({});

  useEffect(() => {
    if (isBrowser()) {
      setBotStyle(enableSendbirdChatBot ? {} : { display: 'none' });
      window.chatbotConfig = CHATBOT_CONFIG;
    }
  }, [enableSendbirdChatBot]);

  return (
    <div
      className='sendbird-bot'
      id={CHATBOT_ROOT_CONTAINER_DIV_ID}
      style={botStyle}
    >
      <Script
        defer='true'
        id='sendbirdChatBotScript'
        src='https://aichatbot.sendbird.com/index.js'
        strategy='lazyOnload'
        type='module'
      />
    </div>
  );
};

export default SendbirdChatBot;
