import { NAMED_QUERY_PARAMS } from '@/config/common';
import { getPaymentPageLink } from '@/services/hostPortal.service';

export const generatePaymentLink = ({
  paymentSourceType,
  referenceId,
  userId
}) => {
  const {
    USER_ID,
    PAYMENT_SOURCE_TYPE: PAYMENT_SOURCE_TYPE_FROM_NAMED_QUERY_PARAM,
    REFERENCE_ID
  } = NAMED_QUERY_PARAMS;

  const url = new URL(getPaymentPageLink());
  url.searchParams.set(USER_ID, userId);
  url.searchParams.set(
    PAYMENT_SOURCE_TYPE_FROM_NAMED_QUERY_PARAM,
    paymentSourceType
  );
  url.searchParams.set(REFERENCE_ID, referenceId);

  return url.toString();
};

export default generatePaymentLink; // TODO: remove this default export once more const are exported from this file
