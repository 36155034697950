import Image from 'next/image';
import PropTypes from 'prop-types';

import { staticMediaStoreBaseURL } from '@/config/common';

const InputField = ({
  alt = 'input',
  baseClass = 'w-full border text-gray-900 px-2 py-4 outline-none placeholder:text-grey-400',
  className,
  dbName,
  disabled,
  errorClass = 'flex-1',
  errors,
  iconClass = '',
  iconHeight = 24,
  iconURL,
  iconWidth = 24,
  inputGroup,
  label = '',
  labelClass = 'text-nero py-1',
  register,
  ...rest
}) => {
  const isInvalidMessage = errors?.[dbName]?.message;
  return (
    <div className={inputGroup}>
      {label && <label className={labelClass}>{label}</label>}
      <div className={`relative ${errorClass}`}>
        {iconURL && (
          <span className={`absolute top-3 left-2 ${iconClass}`}>
            <Image
              {...{
                alt,
                src: `${staticMediaStoreBaseURL}/icons/${iconURL}`,
                height: 0,
                style: { width: iconWidth, height: iconHeight },
                width: 0
              }}
            />
          </span>
        )}
        <input
          {...{
            ...register,
            className: `flex items-center h-12 text-base font-medium text-nero ${baseClass} ${className} ${isInvalidMessage ? 'border-brand' : ''} ${
              disabled
                ? 'cursor-not-allowed'
                : 'cursor-pointer hover:border-brand'
            }`,
            disabled,
            onWheel: (e) => e.target.blur(),
            ...rest
          }}
        />
        {isInvalidMessage && (
          <div className='text-xs text-red-500'>{isInvalidMessage}</div>
        )}
      </div>
    </div>
  );
};

export default InputField;

InputField.propTypes = {
  className: PropTypes.string,
  dbName: PropTypes.string,
  disabled: PropTypes.bool,
  errorClass: PropTypes.string,
  errors: PropTypes.object,
  inputGroup: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  register: PropTypes.any
};
