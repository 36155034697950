import { Avatar, Text } from '@/components/atomic/nuclei';

const HostCreditEntityName = ({
  avatar: { name, badgeStyle, textStyle },
  label: { className: labelClassName, content }
}) => (
  <div className='flex items-center gap-4'>
    <div className='w-[100px] h-[100px] rounded-lg my-4'>
      <Avatar
        {...{
          badgeStyle,
          textStyle,
          name
        }}
      />
    </div>
    <div className='flex flex-col'>
      <Text
        {...{
          className: labelClassName,
          content
        }}
      />
    </div>
  </div>
);

export default HostCreditEntityName;
