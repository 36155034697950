import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';

const TextArea = ({
  alt = 'input',
  baseClass = 'w-full border text-gray-900 px-2 py-2 outline-none placeholder:text-grey-400',
  className,
  dbName,
  disabled,
  errorClass = 'flex-1',
  errors,
  iconHeight = 24,
  iconURL,
  iconWidth = 24,
  inputGroup,
  label = '',
  labelClass = 'text-nero py-1',
  register,
  ...rest
}) => {
  const isInvalidMessage = errors?.[dbName]?.message;

  return (
    <div className={inputGroup}>
      {label && <label className={labelClass}>{label}</label>}
      <div className={`relative ${errorClass}`}>
        {iconURL && (
          <span className='absolute top-2 left-2'>
            <Image
              {...{
                alt,
                height: 0,
                src: `${staticMediaStoreBaseURL}/icons/${iconURL}`,
                style: { width: iconWidth, height: iconHeight },
                width: 0
              }}
            />
          </span>
        )}
        <textarea
          {...{
            ...register,
            className: `${baseClass} ${className} ${isInvalidMessage ? 'border-brand' : ''} ${
              disabled
                ? 'cursor-not-allowed'
                : 'cursor-pointer hover:border-brand'
            }`,
            disabled,
            onWheel: (e) => e.target.blur(),
            ...rest
          }}
        />
        {isInvalidMessage && (
          <div className='text-xs text-red-500'>{isInvalidMessage}</div>
        )}
      </div>
    </div>
  );
};

export default TextArea;
