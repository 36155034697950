import Image from 'next/image';

import { Text, TextWithIcon } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const ListCard = ({
  isSelectedResult,
  listCard: {
    footer: {
      // TODO: FV/HP/Sidd - lets try to convert footer object to a list, so that it can be generic, at the moment because of accountManager it is like patching.
      accountManager: {
        alt: accountManagerAlt,
        icon: accountManagerIcon,
        label: accountManagerLabel
      } = {},
      entityStatus: { statusClass, value } = {},
      entityNumber: {
        alt: entityNumberAlt,
        icon: entityNumberIcon,
        label: entityNumberLabel
      }
    },
    header: {
      subTitle: {
        alt: subHeaderAlt,
        icon: subHeaderIcon,
        value: subHeaderValue,
        isBorder
      } = {},
      titleSubscript,
      title
    },
    icon: { alt: headerIconAlt, headerIcon }
  },
  previewCard,
  setSelectedPreviewCard
}) => (
  <div
    className={`${isSelectedResult ? 'border-brand shadow-navigate-cart' : 'border-neutral'} flex flex-1 gap-4 h-20 border rounded-lg p-3 hover:shadow-product-card`}
    onClick={() => setSelectedPreviewCard(previewCard)}
  >
    <Image
      alt={headerIconAlt}
      height={0}
      priority={true}
      src={`${staticMediaStoreBaseURL}/icons/${headerIcon}`}
      style={{
        height: 50,
        width: 50
      }}
      width={0}
    />
    <div className='flex flex-col self-center gap-1'>
      <div className='flex items-center gap-3'>
        <Text
          {...{
            className: 'text-nero text-xl font-medium max-w-60 truncate',
            content: title
          }}
        />
        {subHeaderValue && (
          <>
            <div className='border-l-2 border-neutral h-4'></div>
            <TextWithIcon
              {...{
                alt: subHeaderAlt,
                className: `${isBorder ? 'border border-neutral rounded' : ''} max-w-40 truncate px-2 py-1`,
                icon: subHeaderIcon,
                iconHeight: 20,
                iconWidth: 20,
                label: subHeaderValue,
                labelStyle: 'text-sm text-nero font-light'
              }}
            />
          </>
        )}
      </div>
      {titleSubscript && (
        <Text
          {...{
            className: 'text-sm text-nero font-light',
            content: titleSubscript
          }}
        />
      )}
    </div>
    <div className='flex gap-4 ml-auto'>
      {accountManagerLabel && (
        <TextWithIcon
          {...{
            alt: accountManagerAlt,
            className: 'border border-neutral rounded px-2 py-1',
            icon: accountManagerIcon,
            iconHeight: 20,
            iconWidth: 20,
            label: accountManagerLabel,
            labelStyle: 'text-sm text-dim-gray font-medium'
          }}
        />
      )}
      {value && (
        <Text
          {...{
            className: `${statusClass} px-2 py-1 self-start rounded text-sm font-medium`,
            content: value
          }}
        />
      )}
      <TextWithIcon
        {...{
          alt: entityNumberAlt,
          className: 'border border-neutral rounded px-2 py-1',
          icon: entityNumberIcon,
          iconHeight: 20,
          iconWidth: 20,
          label: entityNumberLabel,
          labelStyle: 'text-sm text-dim-gray font-medium'
        }}
      />
    </div>
  </div>
);

export default ListCard;
