import Image from 'next/image';

import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { convertToMonthYearFormat } from '@/lib/time';

const LetsPlanFirstEvent = ({ createdAt, labelClassName }) => (
  <div className='flex flex-col w-full gap-3 justify-center items-center'>
    <TextKeyValuePair
      {...{
        className: 'flex flex-row justify-center items-center',
        label: 'Host Since',
        labelClass: `text-xl font-medium ${labelClassName}`,
        value: convertToMonthYearFormat({ dateTimeString: createdAt }),
        valueClassName: `font-semibold text-xl ${labelClassName}`
      }}
    />
    <Text
      {...{
        className: `mb-3 text-2xl font-semibold ${labelClassName}`,
        content: `Let's Plan The First Event!`
      }}
    />
    <Image
      alt='lets-plan-first-event'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/lets-plan-first-event.svg`}
      style={{ width: 293, height: 203 }}
      width={0}
    />
  </div>
);

export default LetsPlanFirstEvent;
