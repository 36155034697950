import {
  ProgressBar,
  Text,
  TextKeyValuePair
} from '@/components/atomic/nuclei';

const HostCreditEntityCreditUtilization = () => (
  <div className='flex flex-col gap-4 bg-brand-gradient text-white px-4 py-6 rounded-lg'>
    <Text
      {...{
        className: 'font-semibold text-base',
        content: 'Credit Limit: AED 100,000'
      }}
    />
    <ProgressBar {...{ progressBarPercentage: 50 }} />
    <div className='flex justify-between w-full'>
      <TextKeyValuePair
        {...{
          className: 'flex flex-col !gap-0',
          label: 'AED 56,000',
          labelClass: 'font-semibold text-base',
          value: 'Credit Balance',
          valueClassName: 'font-medium text-sm'
        }}
      />

      <TextKeyValuePair
        {...{
          className: 'flex flex-col !gap-0',
          label: 'AED 44,000',
          labelClass: 'font-semibold text-base',
          value: 'Outstanding Payment',
          valueClassName: 'font-medium text-sm'
        }}
      />
    </div>
  </div>
);

export default HostCreditEntityCreditUtilization;
