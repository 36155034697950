import Link from 'next/link';

import { Avatar, Text, TextWithIcon } from '@/components/atomic/nuclei';
import { HOST_INTERACTION_STATUS } from '@/config/common';
import { convertToStandardMobileNumberFormat } from '@/lib/mobileNumberValidation';
import { convertToShortMonthDateFormat } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const UserEventInfoColumnOne = ({
  customerSegment,
  email,
  hostCreditEntityName,
  mobile,
  name
}) => {
  const userEventInfoColumnOne = [
    {
      alt: 'mobile',
      icon: 'call-with-frame.svg',
      label: convertToStandardMobileNumberFormat(mobile) || 'NA',
      show: true
    },
    {
      alt: 'email',
      icon: 'email-icon.svg',
      label: email,
      show: Boolean(email)
    },
    {
      alt: 'customerSegment',
      icon: 'user-red-with-frame.svg',
      label: customerSegment?.name,
      show: Boolean(customerSegment?.name)
    },
    {
      alt: 'company',
      icon: 'company-icon.svg',
      label: hostCreditEntityName,
      show: Boolean(hostCreditEntityName)
    }
  ];
  return (
    <div className='flex flex-col mt-2'>
      <Text
        {...{
          className: `text-xl font-medium text-nero truncate max-w-[35rem]`,
          content: name || '-'
        }}
      />

      <div className='flex gap-4 mt-3'>
        {userEventInfoColumnOne.map(({ alt, icon, label, show }) => (
          <TextWithIcon
            key={`${label} - ${icon}`}
            {...{
              alt,
              className: 'py-1 px-2 rounded',
              icon,
              iconHeight: 20,
              iconWidth: 20,
              label,
              labelStyle: `text-sm text-nero`,
              show
            }}
          />
        ))}
      </div>
    </div>
  );
};
const UserEventInfoColumnTwo = ({
  hostNumber,
  interactionStatus,
  createdAt
}) => (
  <div className='flex flex-col ml-auto'>
    <div className='flex justify-content-end ml-auto gap-2 relative -top-[12px] -right-[12px]'>
      <Text
        {...{
          className: `text-sm self-center font-medium px-2 py-1 rounded-lg ${HOST_INTERACTION_STATUS[interactionStatus]?.className}`,
          content: HOST_INTERACTION_STATUS[interactionStatus]?.label
        }}
      />
      <TextWithIcon
        {...{
          alt: 'host number',
          className:
            'curve-shadow rounded-tr rounded-bl relative z-20 px-2 py-2',
          icon: 'number-icon.svg',
          label: parseNumberedLabel(hostNumber),
          labelStyle: 'text-dim-gray text-sm font-medium'
        }}
      />
    </div>
    <div className='flex gap-4 justify-end mt-3'>
      <TextWithIcon
        {...{
          alt: 'date',
          className: 'py-1 px-2 rounded',
          icon: 'calender-grey.svg',
          label: convertToShortMonthDateFormat(createdAt),
          labelStyle: `text-dim-gray text-sm font-light`
        }}
      />
    </div>
  </div>
);

const HostProfileCard = ({ host }) => {
  const {
    createdAt,
    customerSegment,
    email,
    hostCreditEntity,
    hostNumber,
    id: hostId,
    interactionStatus,
    mobile,
    name
  } = host;
  const { name: hostCreditEntityName = null } = hostCreditEntity || {};
  return (
    <Link
      href={getPageURL({
        pageName: PAGE_NAME.HOST_EVENT_LIST.label,
        pathParams: {
          userId: hostId
        }
      })}
    >
      <div className='flex gap-3 bg-white border border-neutral hover:border-brand p-3 shadow-card rounded-lg'>
        <Avatar
          {...{
            badgeStyle: 'w-[74px] h-[74px] bg-brand-gradient',
            name,
            textStyle: 'text-white text-[40px]'
          }}
        />
        <UserEventInfoColumnOne
          {...{
            customerSegment,
            email,
            hostCreditEntityName,
            mobile,
            name
          }}
        />
        <UserEventInfoColumnTwo
          {...{
            createdAt,
            hostNumber,
            interactionStatus
          }}
        />
      </div>
    </Link>
  );
};

export default HostProfileCard;
