import Image from 'next/image';
import Link from 'next/link';
import { PropTypes } from 'prop-types';
import { Fragment } from 'react';

import { staticMediaStoreBaseURL } from '@/config/common';
import copyToClipBoard from '@/lib/copyToClipBoard';

const UuidValue = ({ value, valueClass }) => (
  <div className={valueClass}>{value.substring(0, 8)}</div>
);

const HyperLinkedUuid = ({ link, value, valueClass }) => (
  <Link
    href={link}
    className='self-center'
  >
    <UuidValue {...{ value, valueClass }} />
  </Link>
);

const Uuid = ({
  iconFileName = 'copy.svg',
  label = 'ID: ',
  labelClass,
  link = '',
  value = '',
  valueClass = 'text-base'
}) => (
  <Fragment>
    <div className={labelClass}>{label}</div>
    <div className='flex gap-3'>
      {link ? (
        <HyperLinkedUuid {...{ link, value, valueClass }} />
      ) : (
        <UuidValue {...{ value, valueClass }} />
      )}
      <Image
        alt='copy'
        className='cursor-pointer'
        height={0}
        onClick={() => copyToClipBoard(value)}
        src={`${staticMediaStoreBaseURL}/icons/${iconFileName}`}
        style={{ height: 20, width: 20 }}
        width={0}
      />
    </div>
  </Fragment>
);

export default Uuid;

Uuid.propTypes = {
  labelClass: PropTypes.string,
  value: PropTypes.string
};
