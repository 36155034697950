import { PropTypes } from 'prop-types';

const TextKeyValuePair = ({
  label,
  labelClass = 'text-silver uppercase text-xs md:text-sm',
  value,
  valueClassName = 'text-base md:text-lg',
  className = 'flex-col',
  spaceTop = 'mt-2 md:mt-0'
}) => (
  <div className={`flex gap-2 ${className} ${spaceTop}`}>
    <div className={labelClass}>{label}</div>
    <div className={valueClassName}>{value}</div>
  </div>
);

export default TextKeyValuePair;

TextKeyValuePair.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
