import { Highlight } from 'react-instantsearch';

import {
  CART_STATUS,
  HOST_INTERACTION_STATUS,
  NAVIGATION_ENTITY_TYPE,
  ORDER_STATUS,
  USER_EVENT_STAGE
} from '@/config/common';
import { getSearchkitClient } from '@/helpers/searchkit';
import { convertToStandardMobileNumberFormat } from '@/lib/mobileNumberValidation';
import {
  convertToCurrencyFormat,
  parseFormatPriceValueFromAPI
} from '@/lib/numberStringUtils';
import {
  convertToDateTimeString,
  convertToShortMonthDateFormat
} from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { getEditOrderURL } from '@/services/opsPortal.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';
import { SEARCH_KIT_NAVIGATION_SEARCH_URL } from '@/services/url.service';

export const navigationSearchSearchkitClient = getSearchkitClient({
  url: SEARCH_KIT_NAVIGATION_SEARCH_URL
});

// TODO: Hardik/Faizan - try passing attribute to list and preview card - like - highlight: { flag: true, hit, attribute: 'userEventNumber' },
const getUserEventEntityInfoList = ({
  eventDate,
  eventTime = '',
  hostMobile,
  hostName,
  hostNumber,
  zendeskTicketNumber
}) => [
  {
    icon: 'zendesk-with-frame.svg',
    label: parseNumberedLabel(zendeskTicketNumber)
  },
  {
    icon: 'calendar-red-with-frame.svg',
    label: `${convertToDateTimeString({ date: eventDate, time: eventTime })}`
  },
  {
    icon: 'user-red-with-frame.svg',
    label: hostName
  },
  {
    icon: 'call-with-frame.svg',
    label: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
  },
  {
    icon: 'number-icon.svg',
    label: parseNumberedLabel(hostNumber)
  }
];

const parseUserEventEntity = (hit) => {
  const {
    entityId,
    eventDate,
    eventTime,
    eventType,
    hostMobile,
    hostName,
    hostNumber,
    plannerName,
    stage,
    updatedAt,
    userEventNumber,
    zendeskTicketNumber
  } = hit;
  return {
    listCard: {
      entityId,
      footer: {
        entityNumber: {
          alt: 'user event number',
          icon: 'event-red-icon.svg',
          label: (
            <Highlight
              {...{
                attribute: 'userEventNumber',
                hit
              }}
            />
          )
        },
        entityStatus: {
          statusClass: USER_EVENT_STAGE[stage].class,
          value: USER_EVENT_STAGE[stage].label
        }
      },
      header: {
        subTitle: {
          alt: 'zendesk ticket number',
          icon: 'zendesk-with-frame.svg',
          isBorder: true,
          value: (
            <Highlight
              {...{
                attribute: 'zendeskTicketNumber',
                hit
              }}
            />
          )
        },
        title: eventType,
        titleSubscript: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
      },
      icon: {
        alt: 'event',
        headerIcon: 'list-card-event.svg'
      }
    },
    previewCard: {
      entityId,
      entityInfoList: getUserEventEntityInfoList({
        eventDate,
        eventTime,
        hostMobile,
        hostName,
        hostNumber,
        zendeskTicketNumber
      }),
      footer: {
        ctaList: [
          {
            ctaLabel: 'Event',
            onClick: () => {
              window.open(
                getPageURL({
                  pageName: PAGE_NAME.EVENT_CART_LIST.label,
                  pathParams: { userEventId: entityId }
                }),
                '_blank'
              );
            }
          }
        ],
        lastUpdatedAt: `Last Updated ${convertToShortMonthDateFormat(updatedAt)}`
      },
      header: {
        icon: {
          iconAlt: 'event',
          iconURL: 'navigate-preview-event.svg'
        },
        subTitle: {
          type: 'text',
          text: plannerName
        },
        title: eventType
      },
      subHeader: {
        entityNumber: {
          alt: 'user event number',
          icon: 'event-red-icon.svg',
          label: parseNumberedLabel(userEventNumber)
        },
        subHeaderBody: {
          props: {
            statusClass: USER_EVENT_STAGE[stage].class,
            value: USER_EVENT_STAGE[stage].label
          },
          type: 'Text'
        }
      }
    }
  };
};

const getCartEntityInfoList = ({
  cartTotal,
  createdBy,
  hostMobile,
  hostName,
  hostNumber
}) => [
  {
    icon: 'planner-icon-orange.svg',
    label: createdBy
  },
  {
    icon: 'price-icon.svg',
    label: convertToCurrencyFormat({
      value: parseFormatPriceValueFromAPI(cartTotal)
    })
  },
  {
    icon: 'user-red-with-frame.svg',
    label: hostName
  },
  {
    icon: 'call-with-frame.svg',
    label: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
  },
  {
    icon: 'number-icon.svg',
    label: parseNumberedLabel(hostNumber)
  }
];

const parseCartEntity = (hit) => {
  const {
    cartTotal,
    createdBy,
    entityId,
    entityName,
    entityNumber,
    hostMobile,
    hostName,
    hostNumber,
    status,
    updatedAt,
    userEventNumber
  } = hit;
  const { className: cartStatusClass, label: cartStatusLabel } =
    CART_STATUS[status.toUpperCase()];
  return {
    listCard: {
      entityId,
      footer: {
        entityNumber: {
          alt: 'host number',
          icon: 'order-icon-with-frame-red.svg',
          label: (
            <Highlight
              {...{
                attribute: 'entityNumber',
                hit
              }}
            />
          )
        },
        entityStatus: {
          statusClass: `px-2 py-1 self-start rounded text-sm font-medium ${cartStatusClass}`,
          value: cartStatusLabel
        }
      },
      header: {
        subTitle: {
          alt: 'user event number',
          icon: 'event-red-icon.svg',
          isBorder: true,
          value: (
            <Highlight
              {...{
                attribute: 'userEventNumber',
                hit
              }}
            />
          )
        },
        title: (
          <Highlight
            {...{
              attribute: 'entityName',
              hit
            }}
          />
        ),
        titleSubscript: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
      },
      icon: {
        alt: 'cart',
        headerIcon: 'list-card.svg'
      }
    },
    previewCard: {
      entityId,
      entityInfoList: getCartEntityInfoList({
        cartTotal,
        createdBy,
        hostMobile,
        hostName,
        hostNumber
      }),
      footer: {
        ctaList: [
          {
            ctaLabel: 'Cart',
            onClick: () => {
              const pageName =
                status === CART_STATUS.ORDER.value
                  ? PAGE_NAME.ORDERED_CART.label
                  : PAGE_NAME.PLANNER_CART.label;
              window.open(
                getPageURL({
                  pageName,
                  pathParams: { cartId: entityId }
                }),
                '_blank'
              );
            }
          }
        ],
        lastUpdatedAt: `Last Updated ${convertToShortMonthDateFormat(updatedAt)}`
      },
      header: {
        icon: {
          iconAlt: 'cart',
          iconURL: 'navigate-preview-cart.svg'
        },
        subTitle: {
          alt: 'user event number',
          icon: 'event-red-icon.svg',
          text: parseNumberedLabel(userEventNumber),
          type: 'textWithIcon'
        },
        title: entityName
      },
      subHeader: {
        entityNumber: {
          alt: 'host number',
          icon: 'order-icon-with-frame-red.svg',
          label: parseNumberedLabel(entityNumber)
        },
        subHeaderBody: {
          props: {
            statusClass: `px-2 py-1 self-start rounded text-sm font-medium ${cartStatusClass}`,
            value: cartStatusLabel
          },
          type: 'Text'
        }
      }
    }
  };
};

const getOrderEntityInfoList = ({
  bookingDate,
  hostMobile,
  hostName,
  hostNumber,
  orderTotal
}) => [
  {
    icon: 'order-date.svg',
    label: convertToShortMonthDateFormat(bookingDate)
  },
  {
    icon: 'price-icon.svg',
    label: convertToCurrencyFormat({
      value: parseFormatPriceValueFromAPI(orderTotal)
    })
  },
  {
    icon: 'user-red-with-frame.svg',
    label: hostName
  },
  {
    icon: 'call-with-frame.svg',
    label: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
  },
  {
    icon: 'number-icon.svg',
    label: parseNumberedLabel(hostNumber)
  }
];

const parseOrderEntity = (hit) => {
  const {
    bookingDate,
    entityId,
    entityName,
    entityNumber,
    hostMobile,
    hostName,
    hostNumber,
    orderTotal,
    status,
    updatedAt,
    userEventNumber
  } = hit;
  return {
    listCard: {
      entityId,
      footer: {
        entityNumber: {
          alt: 'order number',
          icon: 'product-icon-bold.svg',
          label: (
            <Highlight
              {...{
                attribute: 'entityNumber',
                hit
              }}
            />
          )
        },
        entityStatus: {
          statusClass: `px-2 py-1 self-start rounded text-sm font-medium ${ORDER_STATUS[status]?.class}`,
          value: ORDER_STATUS[status]?.label
        }
      },
      header: {
        subTitle: {
          alt: 'user event number',
          icon: 'event-red-icon.svg',
          isBorder: true,
          value: (
            <Highlight
              {...{
                attribute: 'userEventNumber',
                hit
              }}
            />
          )
        },
        title: entityName,
        titleSubscript: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
      },
      icon: {
        alt: 'order',
        headerIcon: 'list-card-order.svg'
      }
    },
    previewCard: {
      entityId,
      entityInfoList: getOrderEntityInfoList({
        bookingDate,
        hostMobile,
        hostName,
        hostNumber,
        orderTotal
      }),
      footer: {
        ctaList: [
          {
            ctaLabel: 'Order',
            onClick: () => {
              window.open(getEditOrderURL({ orderId: entityId }), '_blank');
            }
          }
        ],
        lastUpdatedAt: `Last Updated ${convertToShortMonthDateFormat(updatedAt)}`
      },
      header: {
        icon: {
          iconAlt: 'order',
          iconURL: 'navigate-preview-order.svg'
        },
        subTitle: {
          alt: 'user event number',
          icon: 'event-red-icon.svg',
          text: parseNumberedLabel(userEventNumber),
          type: 'textWithIcon'
        },
        title: entityName
      },
      subHeader: {
        entityNumber: {
          alt: 'order number',
          icon: 'product-icon-bold.svg',
          label: parseNumberedLabel(entityNumber)
        },
        subHeaderBody: {
          props: {
            statusClass: `px-2 py-1 self-start rounded text-sm font-medium ${ORDER_STATUS[status]?.class}`,
            value: ORDER_STATUS[status]?.label
          },
          type: 'Text'
        }
      }
    }
  };
};

const getHostEntityInfoList = ({ customerSegment, email, hostMobile }) => [
  {
    icon: 'call-with-frame.svg',
    label: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
  },
  {
    icon: 'user-red-with-frame.svg',
    label: customerSegment || 'NA'
  },

  {
    icon: 'email-icon.svg',
    label: email
  }
];

const parseHostEntity = (hit) => {
  const {
    customerSegment,
    email,
    entityId,
    hostMobile,
    hostName,
    hostNumber,
    interactionStatus,
    updatedAt
  } = hit;

  return {
    listCard: {
      entityId,
      footer: {
        entityNumber: {
          alt: 'host number',
          icon: 'number-icon.svg',
          label: (
            <Highlight
              {...{
                attribute: 'hostNumber',
                hit
              }}
            />
          )
        },
        entityStatus: {
          statusClass: `${HOST_INTERACTION_STATUS[interactionStatus].className} px-2 py-1 self-start rounded text-sm font-medium`,
          value: HOST_INTERACTION_STATUS[interactionStatus].label
        }
      },
      header: {
        title: (
          <Highlight
            {...{
              attribute: 'hostName',
              hit
            }}
          />
        ),
        titleSubscript: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
      },
      icon: {
        alt: 'host',
        headerIcon: 'list-card-host.svg'
      }
    },
    previewCard: {
      entityId,
      entityInfoList: getHostEntityInfoList({
        customerSegment,
        email,
        hostMobile
      }),
      footer: {
        ctaList: [
          {
            ctaLabel: 'Profile',
            onClick: () => {
              window.open(
                getPageURL({
                  pageName: PAGE_NAME.HOST_EVENT_LIST.label,
                  pathParams: { userId: entityId }
                }),
                '_blank'
              );
            }
          }
        ],
        lastUpdatedAt: `Last Updated ${convertToShortMonthDateFormat(updatedAt)}`
      },
      header: {
        icon: {
          iconAlt: 'host',
          iconURL: 'navigate-preview-host.svg'
        },
        title: hostName
      },
      subHeader: {
        entityNumber: {
          alt: 'host number',
          icon: 'number-icon.svg',
          label: parseNumberedLabel(hostNumber)
        },
        subHeaderBody: {
          props: {
            statusClass: `${HOST_INTERACTION_STATUS[interactionStatus].className} px-2 py-1 self-start rounded text-sm font-medium`,
            value: HOST_INTERACTION_STATUS[interactionStatus].label
          },
          type: 'Text'
        }
      }
    }
  };
};

const getHostCreditEntityInfoList = () => [];

const parseHostCreditEntity = (hit) => {
  const { accountManagerName, entityId, entityName, entityNumber, updatedAt } =
    hit;
  return {
    listCard: {
      entityId,
      footer: {
        accountManager: {
          alt: 'account manager',
          icon: 'planner-red.svg',
          label: accountManagerName
        },
        entityNumber: {
          alt: 'host credit entity number',
          icon: 'host-credit-entity-number-red.svg',
          label: (
            <Highlight
              {...{
                attribute: 'entityNumber',
                hit
              }}
            />
          )
        }
      },
      header: {
        title: (
          <Highlight
            {...{
              attribute: 'entityName',
              hit
            }}
          />
        )
      },
      icon: {
        alt: 'host credit entity',
        headerIcon: 'list-cart-host-credit-entity.svg'
      }
    },
    previewCard: {
      entityId,
      entityInfoList: getHostCreditEntityInfoList(),
      footer: {
        ctaList: [
          {
            ctaLabel: 'Profile',
            onClick: () => {
              window.open(
                getPageURL({
                  pageName: PAGE_NAME.HOST_CREDIT_ENTITY_HOST_LIST.label,
                  pathParams: { hostCreditEntityId: entityId }
                }),
                '_blank'
              );
            }
          }
        ],
        lastUpdatedAt: `Last Updated ${convertToShortMonthDateFormat(updatedAt)}`
      },
      header: {
        icon: {
          iconAlt: 'host',
          iconURL: 'navigate-preview-host-credit-entity.svg'
        },
        title: entityName
      },
      subHeader: {
        entityNumber: {
          alt: 'host number',
          icon: 'host-credit-entity-red-icon.svg',
          label: parseNumberedLabel(entityNumber)
        },
        subHeaderBody: {
          type: 'TextWithIcon',
          props: {
            alt: 'account manager',
            icon: 'planner-red.svg',
            label: accountManagerName
          }
        }
      }
    }
  };
};

export const transformSearchResultList = ({ searchResults }) => {
  const transformFunctionMap = {
    [NAVIGATION_ENTITY_TYPE.CART]: parseCartEntity,
    [NAVIGATION_ENTITY_TYPE.HOST]: parseHostEntity,
    [NAVIGATION_ENTITY_TYPE.ORDER]: parseOrderEntity,
    [NAVIGATION_ENTITY_TYPE.USER_EVENT]: parseUserEventEntity,
    [NAVIGATION_ENTITY_TYPE.HOST_CREDIT_ENTITY]: parseHostCreditEntity
  };

  return searchResults.map(({ entityType, ...rest }) =>
    transformFunctionMap[entityType]({ ...rest, entityType })
  );
};

export default transformSearchResultList;
