import { Text } from '@/components/atomic/nuclei';

const PriceSummaryFooter = ({
  absoluteMargin,
  grandTotal,
  haflaPrice,
  margin,
  partnerPrice
}) => (
  <div className='w-full'>
    <div className='flex gap-5 text-sm text-nero font-medium py-3 px-4 border-b border-t border-platinum bg-planner-cart-head rounded-b'>
      <div className='w-108 flex gap-3 self-center'>
        <Text
          {...{
            className: 'self-center flex-1 flex',
            content: grandTotal
          }}
        />
      </div>
      <Text {...{ className: 'w-40 self-center', content: haflaPrice }} />
      <Text {...{ className: 'w-40 self-center', content: partnerPrice }} />
      <Text {...{ className: 'w-56 self-center', content: absoluteMargin }} />
      <Text
        {...{
          className: `w-40 self-center ${
            Number(margin) > 0 ? 'text-green' : 'text-red-500'
          }`,
          content: `${margin} %`
        }}
      />
    </div>
  </div>
);

export default PriceSummaryFooter;
