import Image from 'next/image';
import Link from 'next/link';
import { useRef, useState } from 'react';

import {
  Button,
  HamburgerMenu,
  Text,
  TextWithIcon
} from '@/components/atomic/nuclei';
import { DISPATCH_CTA_STATUS } from '@/config/bid';
import {
  CART_LIST_TYPE,
  CART_STATUS,
  ORDER_STATUS,
  PAYMENT_SOURCE_TYPE,
  staticMediaStoreBaseURL
} from '@/config/common';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import copyToClipBoard from '@/lib/copyToClipBoard';
import { convertToShortMonthDateFormat } from '@/lib/time';
import { getEditOrderURL } from '@/services/opsPortal.service';
import { generatePaymentLink } from '@/services/payment.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';
import { dispatchCTAStatusLabelMap } from '@/services/tender.service';
import {
  cloneCartAPI,
  createNewTenderAndPublishBids,
  invokeArchiveCart
} from '@/services/userEventCartList.service';

const CartInnerBody = ({
  cartCreatedBy,
  createdAt,
  isOrdered,
  orderNumber = '',
  orderStatusClass
}) => {
  const cartKeyValuePairs = [
    {
      className: '',
      icon: 'planner-icon-orange.svg',
      iconHeight: 20,
      iconWidth: 20,
      label: cartCreatedBy,
      labelStyle: `text-nero text-sm font-light ${orderStatusClass}`
    },
    {
      className: '',
      icon: 'calendar-red-with-frame.svg',
      iconHeight: 20,
      iconWidth: 20,
      label: convertToShortMonthDateFormat(createdAt),
      labelStyle: `text-nero text-sm font-light ${orderStatusClass}`
    },
    {
      className: '',
      icon: 'product.svg',
      iconHeight: 20,
      iconWidth: 20,
      label: orderNumber,
      labelStyle: `text-nero text-sm font-light ${orderStatusClass}`,
      visible: isOrdered
    }
  ];
  return (
    <div
      className={`flex flex-wrap gap-4 flex-row gap-y-4 text-sm ${orderStatusClass}`}
    >
      {cartKeyValuePairs.map(
        (
          {
            className,
            icon,
            iconHeight,
            iconWidth,
            label,
            labelStyle,
            visible = true
          },
          index
        ) => (
          <TextWithIcon
            key={index}
            {...{
              className,
              icon,
              iconHeight,
              iconWidth,
              label,
              labelStyle,
              show: visible
            }}
          />
        )
      )}
    </div>
  );
};

const handlePaymentLinkClick = async ({
  id,
  paymentSourceType,
  setShowToast,
  userId
}) => {
  const isClipboardAvailable = Boolean(navigator.clipboard);
  if (isClipboardAvailable) {
    await copyToClipBoard(
      generatePaymentLink({
        paymentSourceType,
        referenceId: id,
        userId
      })
    );
  }
  setShowToast({
    message: isClipboardAvailable
      ? 'Successfully generated and copied payment link'
      : 'Failed to copy to clipboard',
    show: true,
    status: isClipboardAvailable
  });
};

const EventCartCardBodyCTA = ({
  id,
  onClickCopyQuoteLink,
  setShowToast,
  status,
  userInfo
}) => {
  const showLinks = [CART_STATUS.COLLABORATE.value].includes(status);
  const userId = userInfo?.id;
  return (
    showLinks && (
      <div className='flex items-center gap-3 mt-4 md:mt-0 md:self-center'>
        <Button
          {...{
            alt: 'Copy Payment Link',
            className:
              'cursor-pointer leading-6 text-brand border border-brand text-sm rounded px-3 md:px-2 py-[1px] h-7',
            iconGroupClassName: 'gap-1',
            iconHeight: 16,
            iconPosition: 'Left',
            iconURL: `${staticMediaStoreBaseURL}/icons/copy-red.svg`,
            iconWidth: 16,
            id: 'paymentLink',
            label: 'Payment Link',
            onClick: (event) => {
              event.stopPropagation();
              handlePaymentLinkClick({
                setShowToast,
                userId,
                id,
                paymentSourceType: PAYMENT_SOURCE_TYPE.CART
              });
            },
            width: 'w-[150px]'
          }}
        />
        <Button
          {...{
            alt: 'Copy Web Quote Link',
            className:
              'cursor-pointer leading-6 text-brand border border-brand text-sm rounded px-3 md:px-2 py-[2px] h-7',
            iconGroupClassName: 'gap-1',
            iconHeight: 16,
            iconPosition: 'Left',
            iconURL: `${staticMediaStoreBaseURL}/icons/copy-red.svg`,
            iconWidth: 16,
            id: 'customerLink',
            label: 'Web Quote Link',
            onClick: onClickCopyQuoteLink,
            width: 'w-[170px]'
          }}
        />
      </div>
    )
  );
};

const OrderedCartCardBodyCTA = ({
  hidePaymentLinkForOrderCart,
  orderId,
  setShowToast,
  userInfo
}) => (
  <div className='flex items-center gap-3 mt-4 md:mt-0 md:self-center'>
    {!hidePaymentLinkForOrderCart && (
      <Button
        {...{
          alt: 'paymentLink copy',
          className:
            'cursor-pointer leading-6 text-brand border border-brand text-sm rounded px-3 md:px-2 py-[1px] h-7',
          iconGroupClassName: 'gap-1',
          iconHeight: 16,
          iconPosition: 'Left',
          iconURL: `${staticMediaStoreBaseURL}/icons/copy-red.svg`,
          iconWidth: 16,
          id: 'paymentLink',
          label: 'Payment Link',
          onClick: (event) => {
            event.stopPropagation();
            handlePaymentLinkClick({
              id: orderId,
              paymentSourceType: PAYMENT_SOURCE_TYPE.ORDER,
              setShowToast,
              userId: userInfo?.id
            });
          },
          width: 'w-[155px]'
        }}
      />
    )}

    <div>
      <Link
        className='flex items-center gap-1 bg-white border border-brand text-brand text-sm font-medium rounded px-2 py-[2px] h-7'
        href={getEditOrderURL({ orderId })}
        onClick={(e) => e.stopPropagation()}
        target='_blank'
      >
        <div className='flex items-center w-6 h-6 self-center'>
          <Image
            {...{
              alt: 'manage order',
              height: 0,
              src: `${staticMediaStoreBaseURL}/icons/open-new-tab-gradient.svg`,
              style: { height: 20, width: 20 },
              width: 0
            }}
          />
        </div>
        <Text {...{ className: 'self-center', content: 'Manage Order' }} />
      </Link>
    </div>
  </div>
);

const CartCardBodyCTAType = {
  [CART_LIST_TYPE.ARCHIVE]: EventCartCardBodyCTA,
  [CART_LIST_TYPE.EVENT]: EventCartCardBodyCTA,
  [CART_LIST_TYPE.ORDER]: OrderedCartCardBodyCTA
};

const CartCardBody = ({
  cartCreatedBy,
  cartListType,
  cartName,
  cartNumber,
  cartOrder,
  createdAt,
  deliveryDate,
  dispatchCTALabel,
  id,
  isActiveTenderAvailable,
  isArchivedCart,
  isCartInOrderState,
  onClickCopyQuoteLink,
  pickupDate,
  router,
  setEditCartSummaryModal,
  setLoading,
  setOpenCloneCartModal,
  setShowPDFDownloadConfirmationModal,
  setShowToast,
  setUserEventCartDetails,
  status,
  updatedAt,
  userEventId,
  userId,
  userInfo
}) => {
  const CartCardBodyCTA = CartCardBodyCTAType[cartListType];
  const [showMenu, setShowMenu] = useState(false);
  const hamburgerMenuRef = useRef(null);
  const isOrdered = status === CART_STATUS.ORDER.value;
  const isOrderInCancelledState =
    isOrdered && cartOrder.order.status === ORDER_STATUS.CANCELLED.value;
  const orderStatusClass = isOrderInCancelledState ? 'cancelled' : '';

  const orderedCartCardMenuOptions = [
    {
      alt: 'Bid Responses',
      hoverIcon: 'view-icon-with-frame-red.svg',
      icon: 'view-gray-icon.svg',
      isInactive: !isActiveTenderAvailable,
      label: 'Bid Responses',
      onClick: () => {
        window.open(
          getPageURL({
            excludeBaseUrl: false,
            pageName: PAGE_NAME.TENDER_BID_RESPONSE.label,
            pathParams: { referenceId: id }
          }),
          '_blank'
        );
      }
    },
    {
      alt: 'Clone Cart',
      hoverIcon: 'clone-cart-gray-icon-red.svg',
      icon: 'clone-cart-gray-icon.svg',
      label: 'Clone Cart',
      onClick: () =>
        cloneCartAPI({
          cartId: id,
          cartStatus: status,
          setLoading,
          setShowToast,
          setUserEventCartDetails,
          userEventId,
          userId
        })
    },
    {
      alt: 'View Order',
      hoverIcon: 'view-icon-with-frame-red.svg',
      icon: 'view-gray-icon.svg',
      label: 'View Order',
      onClick: () => {
        const orderId = cartOrder.order.id;
        router.push(
          getPageURL({
            pageName: PAGE_NAME.ORDER_VIEW.label,
            pathParams: { orderId }
          })
        );
      }
    },
    {
      alt: 'Export Cart',
      hoverIcon: 'export-red-icon.svg',
      icon: 'export-gray-icon.svg',
      label: 'Export Cart',
      onClick: () => {
        setOpenCloneCartModal(true);
      }
    }
  ];

  const nonOrderedCartMenuOptions = [
    {
      alt: 'Edit Cart Info',
      hoverIcon: 'edit-red-with-frame.svg',
      icon: 'edit-gray-with-frame.svg',
      label: 'Edit Cart Info',
      onClick: () =>
        setEditCartSummaryModal({
          showModal: true,
          cartSummary: {
            cartName,
            cartNumber,
            createdAt,
            deliveryDate,
            id,
            pickupDate,
            status,
            updatedAt
          }
        })
    },
    {
      alt: 'Create Tender Publish Bids',
      hoverIcon: 'send-icon.svg',
      icon: 'send-icon-gray.svg',
      label: dispatchCTAStatusLabelMap[dispatchCTALabel],
      onClick: () => {
        if (dispatchCTALabel !== DISPATCH_CTA_STATUS.REQUESTING_BID.value) {
          createNewTenderAndPublishBids({
            cartId: id,
            firstTender:
              dispatchCTALabel === DISPATCH_CTA_STATUS.REQUEST_BID.value,
            setLoading,
            setShowToast,
            setUserEventCartDetails,
            userEventId
          });
        }
      }
    },
    {
      alt: 'Bid Responses',
      hoverIcon: 'view-icon-with-frame-red.svg',
      icon: 'view-gray-icon.svg',
      label: 'Bid Responses',
      onClick: () => {
        window.open(
          getPageURL({
            excludeBaseUrl: false,
            pageName: PAGE_NAME.TENDER_BID_RESPONSE.label,
            pathParams: { referenceId: id }
          }),
          '_blank'
        );
      }
    },
    {
      alt: 'Download Quote',
      hoverIcon: 'download-red-with-frame.svg',
      icon: 'download-gray-with-frame.svg',
      label: 'Download Quote',
      onClick: () => setShowPDFDownloadConfirmationModal(true)
    },
    {
      alt: 'Clone Cart',
      hoverIcon: 'clone-cart-gray-icon-red.svg',
      icon: 'clone-cart-gray-icon.svg',
      label: 'Clone Cart',
      onClick: () =>
        cloneCartAPI({
          cartId: id,
          cartStatus: status,
          setLoading,
          setShowToast,
          setUserEventCartDetails,
          userEventId,
          userId
        })
    },
    {
      alt: `${isArchivedCart ? 'Unarchive' : 'Archive'} cart`,
      hoverIcon: 'archive-red.svg',
      icon: 'archive-gray.svg',
      label: `${isArchivedCart ? 'Unarchive' : 'Archive'} cart`,
      onClick: () =>
        invokeArchiveCart({
          cartId: id,
          isArchived: !isArchivedCart,
          setLoading,
          setShowToast,
          setUserEventCartDetails,
          userEventId,
          userId
        })
    },
    {
      alt: 'Export Cart',
      hoverIcon: 'export-red-icon.svg',
      icon: 'export-gray-icon.svg',
      label: 'Export Cart',
      onClick: () => {
        setOpenCloneCartModal(true);
      }
    }
  ];

  const menuOptions = isCartInOrderState
    ? orderedCartCardMenuOptions
    : nonOrderedCartMenuOptions;

  useOnClickOutside(hamburgerMenuRef, () => setShowMenu(false));

  return (
    <div
      className='flex flex-col md:flex-row items-center justify-between'
      ref={hamburgerMenuRef}
    >
      <CartInnerBody
        {...{
          cartCreatedBy,
          createdAt,
          isOrdered,
          orderNumber: cartOrder?.order.orderNumber,
          orderStatusClass
        }}
      />
      <div className='flex gap-3'>
        <CartCardBodyCTA
          {...{
            hidePaymentLinkForOrderCart: true,
            id,
            onClickCopyQuoteLink,
            orderId: cartOrder?.order.id,
            setShowPDFDownloadConfirmationModal,
            setShowToast,
            status,
            userInfo
          }}
        />

        <HamburgerMenu
          {...{
            menuOptions,
            setShowMenu,
            showMenu
          }}
        />
      </div>
    </div>
  );
};

export default CartCardBody;
