const timeList = [
  {
    time: '06:00am',
    value: '06:00'
  },
  {
    time: '06:30am',
    value: '06:30'
  },
  {
    time: '07:00am',
    value: '07:00'
  },
  {
    time: '07:30am',
    value: '07:30'
  },
  {
    time: '08:00am',
    value: '08:00'
  },
  {
    time: '08:30am',
    value: '08:30'
  },
  {
    time: '09:00am',
    value: '09:00'
  },
  {
    time: '09:30am',
    value: '09:30'
  },
  {
    time: '10:00am',
    value: '10:00'
  },
  {
    time: '10:30am',
    value: '10:30'
  },
  {
    time: '11:00am',
    value: '11:00'
  },
  {
    time: '11:30am',
    value: '11:30'
  },
  {
    time: '12:00pm',
    value: '12:00'
  },
  {
    time: '12:30pm',
    value: '12:30'
  },
  {
    time: '01:00pm',
    value: '13:00'
  },
  {
    time: '01:30pm',
    value: '13:30'
  },
  {
    time: '02:00pm',
    value: '14:00'
  },
  {
    time: '02:30pm',
    value: '14:30'
  },
  {
    time: '03:00pm',
    value: '15:00'
  },
  {
    time: '03:30pm',
    value: '15:30'
  },
  {
    time: '04:00pm',
    value: '16:00'
  },
  {
    time: '04:30pm',
    value: '16:30'
  },
  {
    time: '05:00pm',
    value: '17:00'
  },
  {
    time: '05:30pm',
    value: '17:30'
  },
  {
    time: '06:00pm',
    value: '18:00'
  },
  {
    time: '06:30pm',
    value: '18:30'
  },
  {
    time: '07:00pm',
    value: '19:00'
  },
  {
    time: '07:30pm',
    value: '19:30'
  },
  {
    time: '08:00pm',
    value: '20:00'
  },
  {
    time: '08:30pm',
    value: '20:30'
  },
  {
    time: '09:00pm',
    value: '21:00'
  },
  {
    time: '09:30pm',
    value: '21:30'
  },
  {
    time: '10:00pm',
    value: '22:00'
  },
  {
    time: '10:30pm',
    value: '22:30'
  },
  {
    time: '11:00pm',
    value: '23:00'
  },
  {
    time: '11:30pm',
    value: '23:30'
  },
  {
    time: '12:00am',
    value: '24:00'
  }
];

export default timeList;
