import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const Title = ({ title }) => (
  <Text
    className='text-base font-light my-2'
    content={title}
  />
);

const DefaultNoItemIconOptions = {
  alt: 'No Item Found',
  height: '80',
  layout: 'fixed',
  src: 'sad-icon.svg',
  width: '80'
};

const DefaultActionIconOptions = {
  alt: 'Add New Item',
  height: '20',
  layout: 'fixed',
  src: 'plus-icon.svg',
  width: '20'
};

const DefaultActionOptions = {
  hide: false,
  onClick: () => true,
  title: 'Add New Item',
  type: 'onClick'
};

const Icon = ({ icon = {} }) => {
  const { src, width, height, alt, layout } = {
    ...DefaultNoItemIconOptions,
    ...icon
  };
  return (
    <Image
      alt={alt}
      className='mx-auto'
      height={height}
      layout={layout}
      src={`${staticMediaStoreBaseURL}/icons/${src}`}
      width={width}
    />
  );
};

const Button = ({ icon, title }) => (
  <Fragment>
    <Icon {...{ icon }} />
    <Text
      className='text-sm font-medium text-brand'
      content={title}
    />
  </Fragment>
);

const LinkButton = ({ title, icon, link, hide = false }) =>
  !hide && (
    <Link
      className='flex gap-1 justify-center'
      href={link}
      legacyBehavior
    >
      <Button {...{ icon, title }} />
    </Link>
  );

const OnclickButton = ({ title, icon, onClick, hide = false }) =>
  !hide && (
    <div
      className='cursor-pointer flex justify-center gap-2'
      onClick={onClick}
    >
      <Button {...{ icon, title }} />
    </div>
  );

const ActionButtonMap = {
  link: LinkButton,
  onClick: OnclickButton
};

const NoItemFound = ({
  action,
  actionIcon,
  noItemIcon,
  title = 'No Item Found'
}) => {
  const actionProps = {
    ...DefaultActionOptions,
    ...action,
    icon: { ...DefaultActionIconOptions, ...actionIcon }
  };
  const ActionButton = ActionButtonMap[actionProps.type];

  return (
    <div className='flex text-center gap-2 flex-col justify-center shadow-profile h-126 my-4 rounded-lg bg-white'>
      <div className='self-center'>
        <div className='opacity-30'>
          <Icon {...{ icon: noItemIcon }} />
        </div>
        <Title {...{ title }} />
        <ActionButton {...{ ...actionProps }} />
      </div>
    </div>
  );
};

export default NoItemFound;
