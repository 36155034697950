import Image from 'next/image';
import { PropTypes } from 'prop-types';

import { staticMediaStoreBaseURL } from '@/config/common';

const MenuOptions = ({ menuOptions, setShowMenu, showMenu }) =>
  showMenu && (
    <div className='absolute top-10 z-50 -left-40 w-48 bg-white rounded py-0 flex flex-col text-sm text-dim-gray font-medium shadow-card'>
      {menuOptions.map(
        (
          { alt, label, icon, hoverIcon, onClick, isInactive = false },
          index
        ) => (
          <div
            key={index}
            className={`flex gap-1 px-4 py-2 hover:bg-neutral hover:text-nero hamburger-menu-item ${isInactive ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            onClick={(e) => {
              e.stopPropagation();
              if (!isInactive) {
                onClick();
                setShowMenu(false);
              }
            }}
          >
            <div className='group-menu-icon'>
              <Image
                {...{
                  alt,
                  height: 0,
                  src: `${staticMediaStoreBaseURL}/icons/${icon}`,
                  style: { height: 20, width: 20 },
                  width: 0
                }}
              />
            </div>
            <div className='group-menu-icon-hover hidden'>
              <Image
                {...{
                  alt,
                  height: 0,
                  src: `${staticMediaStoreBaseURL}/icons/${hoverIcon}`,
                  style: { height: 20, width: 20 },
                  width: 0
                }}
              />
            </div>
            <div className={`${label === 'Book Order' ? 'leading-6' : ''}`}>
              {label}
            </div>
          </div>
        )
      )}
    </div>
  );

const HamburgerMenu = ({ menuOptions, setShowMenu, showMenu }) => (
  <div className='relative'>
    <div
      onClick={(e) => {
        e.stopPropagation();
        setShowMenu(!showMenu);
      }}
    >
      <Image
        {...{
          alt: 'hamburger cta',
          className: 'cursor-pointer',
          height: 0,
          src: `${staticMediaStoreBaseURL}/icons/${showMenu ? 'active-hamburger-icon' : 'hamburger-icon-gradient'}.svg`,
          style: { height: 28, width: 28 },
          width: 0
        }}
      />
    </div>
    <MenuOptions {...{ menuOptions, setShowMenu, showMenu }} />
  </div>
);

export default HamburgerMenu;

HamburgerMenu.propTypes = {
  menuOptions: PropTypes.arrayOf(PropTypes.object),
  setShowMenu: PropTypes.func,
  showMenu: PropTypes.bool
};
