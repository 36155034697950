import { OPS_PORTAL_URL } from '@/services/connections.service';

export const getOpsPortalLoginURL = () => `${OPS_PORTAL_URL}/login`;

export const getEditOrderURL = ({ orderId }) =>
  `${OPS_PORTAL_URL}/orders/${orderId}/details/order-cart-details`;

export const getHostListURL = () => `${OPS_PORTAL_URL}/customers`;
export const getMetabaseDashboardHomeURL = () => `${OPS_PORTAL_URL}/dashboard`;
export const getEditCustomerProfileURL = ({ hostId }) =>
  `${OPS_PORTAL_URL}/customers/${hostId}/edit`;
