import { GoogleAnalytics } from '@next/third-parties/google';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { captureGTMEventPageView } from 'lib/gtm';

const googleAnalyticsTag =
  process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID;

const RecordPageViewEvent = () => {
  const router = useRouter();

  useEffect(() => {
    if (router?.asPath) {
      captureGTMEventPageView(router.asPath);
    }
  }, [router?.asPath]);

  return <GoogleAnalytics gaId={googleAnalyticsTag} />;
};

export default RecordPageViewEvent;
