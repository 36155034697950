import { RichTextEditor, Text } from '@/components/atomic/nuclei';

const WIPCartSummaryNote = ({
  dbName,
  disabled,
  noteType = 'EXTERNAL',
  setValue,
  value
}) => {
  const noteConfig = {
    EXTERNAL: {
      label: 'External Notes',
      style: `${!disabled ? 'bg-white' : 'bg-ghost-white'}`
    },
    INTERNAL: { label: 'Internal Notes', style: 'bg-highlight' }
  };

  const { label, style } = noteConfig[noteType];

  return (
    <div className='relative'>
      <Text
        {...{
          className: 'text-sm text-nero mb-2 absolute top-2 font-medium',
          content: label
        }}
      />
      <div
        className={`${style} rich-text-editor mt-8 border border-platinum rounded-md p-2 h-48 overflow-hidden`}
      >
        <RichTextEditor
          {...{
            dbKey: dbName,
            editorStyle: 'overflow-auto h-36',
            readOnly: disabled,
            setValue,
            values: value
          }}
        />
      </div>
    </div>
  );
};

export default WIPCartSummaryNote;
