const getCurrentGeolocation = async () =>
  new Promise((res, rej) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { longitude, latitude } }) => {
        res({ latitude, longitude });
      },
      (error) => {
        rej(error);
      }
    );
  });

export default getCurrentGeolocation;
