import { useState } from 'react';

const useTooltip = (initialState = {}) => {
  const [tooltip, setTooltip] = useState(initialState);

  const toggleTooltip = (tooltipId) => {
    setTooltip((prevState) => ({
      ...prevState,
      [tooltipId]: !prevState[tooltipId]
    }));
  };

  return {
    toggleTooltip,
    tooltip
  };
};

export default useTooltip;
