import { PAGE_NAME } from '@/services/plannerPortal.service';

export const enableSendbirdChatBotInEnv = ['uat', 'production'].includes(
  process.env.NEXT_PUBLIC_APP_ENV
);

export const sendbirdChatbotEnabledPagePathList = [
  PAGE_NAME.PLANNER_ASSISTANT.template
];

export const CHATBOT_ROOT_CONTAINER_DIV_ID = 'aichatbot';

export const CHATBOT_CONFIG = [
  '907FBC27-019D-4CC3-99D4-0DFEED73F497',
  'product-search-1',
  {
    apiHost: 'https://api-cf-ap-8.sendbird.com'
  }
];
