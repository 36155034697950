import { PropTypes } from 'prop-types';

const ProductMediaToggle = ({
  disable = false,
  isShowProductMedia,
  onClickToggleButton
}) => (
  <div>
    <div className='flex gap-3'>
      <label className='switch'>
        <input
          checked={isShowProductMedia}
          disabled={disable}
          onChange={(e) =>
            onClickToggleButton({ showProductMediaFlag: e.target.checked })
          }
          type='checkbox'
        />
        <span className='toggle-slider round'></span>
      </label>
      <span className='self-center text-base text-dim-gray font-medium'>
        Show Existing Media to Hosts and Partners
      </span>
    </div>
    {isShowProductMedia && (
      <div className='text-sm font-light text-white py-3 px-4 rounded bg-brand-gradient mt-4'>
        Existing media will be displayed to the hosts and partners on the web
        quote, carts, bids, and orders page
      </div>
    )}
  </div>
);

export default ProductMediaToggle;

ProductMediaToggle.propTypes = {
  isShowProductMedia: PropTypes.bool,
  onClickToggleButton: PropTypes.func
};
