import Image from 'next/image';

import { Button, Text, TextWithIcon } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const Header = ({
  header: {
    icon: { iconAlt, iconURL },
    subTitle: {
      alt: subTitleAlt,
      icon: subTitleIcon,
      text: subTitleText,
      type: subTitleType
    } = {},
    title
  }
}) => (
  <div className='flex items-center gap-5'>
    <Image
      alt={iconAlt}
      height={0}
      priority={true}
      src={`${staticMediaStoreBaseURL}/icons/${iconURL}`}
      style={{
        height: 100,
        width: 100
      }}
      width={0}
    />
    <div className='flex flex-col gap-1'>
      <Text
        {...{
          className:
            'text-xl font-medium text-brand line-clamp-2 max-w-[18rem] break-words',
          content: title
        }}
      />
      {subTitleType === 'textWithIcon' ? (
        <TextWithIcon
          {...{
            alt: subTitleAlt,
            className: 'border border-neutral rounded px-2 py-1 w-fit',
            icon: subTitleIcon,
            iconHeight: 20,
            iconWidth: 20,
            label: subTitleText,
            labelStyle: 'text-sm text-dim-gray font-medium'
          }}
        />
      ) : (
        <Text
          {...{
            className: 'text-base font-medium text-nero',
            content: subTitleText
          }}
        />
      )}
    </div>
  </div>
);

const SubHeader = ({
  subHeader: {
    entityNumber: {
      alt: entityNumberAlt,
      icon: entityNumberIcon,
      label: entityNumberLabel
    },
    subHeaderBody: { props, type } = {}
  }
}) => (
  <div className='flex justify-between'>
    <div>
      {type === 'TextWithIcon' && (
        <TextWithIcon
          {...{
            alt: props.alt,
            className: 'border border-neutral rounded px-2 py-1',
            icon: props.icon,
            iconHeight: 20,
            iconWidth: 20,
            label: props.label,
            labelStyle: 'text-sm text-dim-gray font-medium truncate max-w-96'
          }}
        />
      )}
    </div>
    <div className='flex gap-2'>
      {type === 'Text' && (
        <Text
          {...{
            className: `${props.statusClass} px-2 py-1 self-start rounded text-sm font-medium`,
            content: props.value
          }}
        />
      )}
      <TextWithIcon
        {...{
          alt: entityNumberAlt,
          className: 'border border-neutral rounded px-2 py-1',
          icon: entityNumberIcon,
          iconHeight: 20,
          iconWidth: 20,
          label: entityNumberLabel,
          labelStyle: 'text-sm text-dim-gray font-medium truncate max-w-96'
        }}
      />
    </div>
  </div>
);

const Body = ({ entityInfoList }) => (
  <div className='flex flex-col gap-3 mt-1'>
    {entityInfoList.map(({ icon, label }, index) => (
      <TextWithIcon
        key={`${label}-${index}`}
        {...{
          className: 'shadow-none',
          icon,
          iconHeight: 24,
          iconWidth: 24,
          label,
          labelStyle: 'text-base text-dim-gray font-medium truncate max-w-96'
        }}
      />
    ))}
  </div>
);

const Footer = ({
  footer: {
    ctaList: [leftCTA],
    lastUpdatedAt
  }
}) => (
  <div className='w-[405px] absolute bottom-3'>
    <Button
      {...{
        className:
          'bg-brand-gradient text-white rounded-lg py-[10px] text-sm font-medium',
        label: `View ${leftCTA.ctaLabel}`,
        onClick: leftCTA.onClick
      }}
    />
    <Text
      {...{
        className: 'text-sm text-dim-gray font-light text-center mt-4',
        content: lastUpdatedAt
      }}
    />
  </div>
);

const PreviewCard = ({
  selectedPreviewCard: { entityInfoList, footer, header, subHeader }
}) => (
  <div className='relative w-[432px] h-[38rem]'>
    <div className='bg-white fixed right-6 border shadow-navigate-cart flex flex-col gap-4 border-brand px-3 py-4 rounded-lg w-[432px] h-[38rem]'>
      <Header {...{ header }} />
      <SubHeader {...{ subHeader }} />
      <Body {...{ entityInfoList }} />
      <Footer {...{ footer }} />
    </div>
  </div>
);

export default PreviewCard;
