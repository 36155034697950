import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { PRODUCT_TYPE } from '@/config/common';
import {
  parseFormatPercentValueFromAPI,
  parseFormatPriceValueFromAPI
} from '@/lib/numberStringUtils';
import { getRackPriceForOrderedCartItem } from '@/services/cart.plan.service';

const DiscountPercentWithPriceStrike = ({ discountPercent, unitRackPrice }) => (
  <TextKeyValuePair
    {...{
      className: 'flex gap-2',
      label: `AED ${unitRackPrice}`,
      labelClass: 'line-through text-xs text-dim-gray self-center',
      value: `${discountPercent}% off`,
      valueClassName: 'text-green text-md font-medium'
    }}
  />
);

const RackRateOfOrderedCartItem = ({
  rackDiscountPercentage,
  type: productType,
  unitListedDiscount,
  unitListedPrice,
  unitRackPrice
}) => {
  const rackPriceString = getRackPriceForOrderedCartItem({
    rackDiscountPercentage,
    type: productType,
    unitListedPrice
  });

  const showDiscountPercentWithPriceStrike =
    productType === PRODUCT_TYPE.CART && Boolean(unitListedDiscount);

  return (
    <>
      <Text {...{ content: rackPriceString }} />
      {showDiscountPercentWithPriceStrike && (
        <DiscountPercentWithPriceStrike
          {...{
            discountPercent: parseFormatPercentValueFromAPI(
              rackDiscountPercentage || 0
            ),
            unitRackPrice: parseFormatPriceValueFromAPI(unitRackPrice)
          }}
        />
      )}
    </>
  );
};

export default RackRateOfOrderedCartItem;
