import { useRouter } from 'next/router';

import { Button } from '@/components/atomic/nuclei';
import { useUIContext } from '@/components/context/UiContext/ManagedUiContext';
import { PAGE_NAME, getPageURL } from '@/services/plannerPortal.service';

const AddProductToCart = ({ cartDetails }) => {
  const { cartName, cartNumber, cartItems, id, status, userCartId } =
    cartDetails;

  const { updateActivePlannerCart } = useUIContext();
  const router = useRouter();
  return (
    <Button
      {...{
        className:
          'border border-brand border-dashed text-center py-5 text-brand rounded-lg font-medium text-sm',
        label: '+ Add Product',
        onClick: () => {
          const activePlannerCart = {
            cartItemCount: cartItems?.length,
            cartName,
            cartNumber,
            id,
            isVerified: true,
            status,
            userCartId
          };
          updateActivePlannerCart(activePlannerCart);
          router.push(
            getPageURL({
              pageName: PAGE_NAME.PRODUCT_SEARCH_RESULT.label
            })
          );
        }
      }}
    />
  );
};

export default AddProductToCart;
