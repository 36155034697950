import { DISPATCH_CTA_STATUS } from '@/config/bid';
import * as HttpService from '@/services/http.service';
import {
  CREATE_TENDER_AND_DISPATCH_BIDS,
  REVISE_TENDER_AND_DISPATCH_BIDS
} from '@/services/url.service';

const createTenderAndPublishBidsRevision = (data) =>
  HttpService.postWithAuth(
    REVISE_TENDER_AND_DISPATCH_BIDS({ referenceId: data.cartId }),
    data
  );

const createTenderAndPublishBidsFirstIteration = (data) =>
  HttpService.postWithAuth(CREATE_TENDER_AND_DISPATCH_BIDS(), data);

export const dispatchCTAStatusLabelMap = {
  [`${DISPATCH_CTA_STATUS.REQUEST_BID.value}`]:
    DISPATCH_CTA_STATUS.REQUEST_BID.label,
  [`${DISPATCH_CTA_STATUS.REQUESTING_BID.value}`]:
    DISPATCH_CTA_STATUS.REQUESTING_BID.label,
  [`${DISPATCH_CTA_STATUS.REQUEST_AGAIN.value}`]:
    DISPATCH_CTA_STATUS.REQUEST_AGAIN.label
};

export const getDispatchCTALabel = ({ tenders = [] }) => {
  const dispatchedTendersCount = tenders.filter(
    ({ bidsCreationStatus }) => bidsCreationStatus === 'BIDS_CREATED'
  ).length;
  const dispatchInProgressTendersCount = tenders.filter(
    ({ bidsCreationStatus }) => bidsCreationStatus === 'IN_PROGRESS'
  ).length;
  if (dispatchInProgressTendersCount > 0) {
    return DISPATCH_CTA_STATUS.REQUESTING_BID.value;
  }
  if (dispatchedTendersCount > 0) {
    return DISPATCH_CTA_STATUS.REQUEST_AGAIN.value;
  }
  return DISPATCH_CTA_STATUS.REQUEST_BID.value;
};

export const createTenderAndPublishBids = ({ firstTender, data }) =>
  firstTender
    ? createTenderAndPublishBidsFirstIteration(data)
    : createTenderAndPublishBidsRevision(data);
