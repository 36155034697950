import { Text } from '@/components/atomic/nuclei';
import { PRODUCT_TYPE } from '@/config/common';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';
import { getSelectedPartner } from '@/services/cart.plan.service';

const CartItemSummary = ({
  cartItem,
  isPricedCartItem = false,
  isUnAvailableCartItem = false
}) => {
  const { derivedValues, partnerId, product, quantity } = cartItem;
  const { haflaMarginPercent, sellingPrice, unitListedPrice } = derivedValues;
  const {
    price: { minPrice, price },
    productPartners,
    type: productType
  } = product;

  const unitPriceBasedOnProductTypeMap = {
    [PRODUCT_TYPE.CART]: parseFormatPriceValueFromAPI(Number(price || 0)),
    [PRODUCT_TYPE.QUOTE]: `Price starting from AED ${parseFormatPriceValueFromAPI(
      Number(minPrice)
    )}`
  };

  const isPositiveMarginAndValidProduct =
    Number(parseFormatPriceValueFromAPI(haflaMarginPercent)) >= 0 &&
    !isUnAvailableCartItem;

  const cartItemSummary = [
    {
      className: 'w-40',
      content: unitPriceBasedOnProductTypeMap[productType],
      show: !isPricedCartItem
    },
    {
      className: 'w-20',
      content: quantity
    },
    {
      className: 'w-24',
      content: parseFormatPriceValueFromAPI(unitListedPrice)
    },
    {
      className: 'w-30',
      content: parseFormatPriceValueFromAPI(sellingPrice)
    },
    {
      className: 'w-40',
      content: getSelectedPartner({ productPartners, partnerId })
    },
    {
      className: `w-20 ${
        isPositiveMarginAndValidProduct ? 'text-green' : 'text-red-500'
      }`,
      content: `${parseFormatPriceValueFromAPI(haflaMarginPercent)} %`
    }
  ];
  return cartItemSummary.map(
    ({ className, content, show = true }, index) =>
      show && (
        <Text
          key={`${content} - ${index}`}
          {...{
            className: `${className} self-center ${isUnAvailableCartItem ? 'text-red-600' : ''}`,
            content
          }}
        />
      )
  );
};

export default CartItemSummary;
