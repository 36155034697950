import { Text, TextWithIcon } from '@/components/atomic/nuclei';
import { getUserEventCartDetails } from '@/services/cart.plan.service';

const CartEventInfo = ({ cartDetails, userEventSummary }) => {
  const { userEventDetails: [userEventDetails] = [] } = userEventSummary || {};
  const { eventTitle } = userEventDetails;
  const { userEventDetailsBody } = getUserEventCartDetails({
    userCartDetails: cartDetails
  });
  return (
    <div className='p-5 bg-lightgray rounded-b-md'>
      <div className='flex flex-col md:flex-row justify-start gap-4'>
        <Text
          className='font-semibold text-nero'
          content={eventTitle || 'NA'}
        />
      </div>
      <div className='flex flex-col md:flex-row justify-between bg-lightgray mt-4'>
        <div className='grid grid-cols-1 md:grid-cols-4 md:grid-rows-2 gap-2 mt-2 md:mt-0'>
          {userEventDetailsBody.map(({ alt, className, icon, value }) => (
            <div
              className={`flex gap-3 md:gap-2 md:w-96 ${className}`}
              key={value}
            >
              <TextWithIcon
                {...{
                  alt,
                  className: 'flex',
                  icon,
                  iconHeight: 20,
                  iconWidth: 20,
                  label: value,
                  labelStyle:
                    'flex self-center text-sm md:text-base font-light text-nero max-w-52 truncate'
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CartEventInfo;
