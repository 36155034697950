import Image from 'next/image';

import { TextKeyValuePair } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const ApplyForCreditBanner = () => (
  <div className='flex flex-col'>
    <div className='flex justify-between bg-light-red w-full rounded-xl p-6 my-6'>
      <TextKeyValuePair
        {...{
          label: 'No Credit Limit',
          labelClass: 'font-semibold text-brand',
          value: 'Apply for Credit',
          valueClassName: 'font-medium text-brand text-sm'
        }}
      />

      <Image
        alt='Apply for Credit'
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/no-credit-icon.svg`}
        style={{ height: 112, width: 194 }}
        width={0}
      />
    </div>
  </div>
);

export default ApplyForCreditBanner;
