import { PropTypes } from 'prop-types';
import { Fragment } from 'react';

import { TextKeyValuePair } from '@/components/atomic/nuclei';

const getItemDetails = ({ containerClass, priceLabel, quantity }) => [
  {
    className: `text-xs md:text-base font-medium ${containerClass}`,
    label: 'Quantity: ',
    labelClass: '',
    value: quantity,
    valueClassName: ''
  },
  {
    className: `text-xs md:text-base font-medium ${containerClass}`,
    label: 'Total: AED ',
    labelClass: '',
    value: priceLabel,
    valueClassName: ''
  }
];

const ItemCommonDetails = ({ containerClass, priceLabel, quantity }) => (
  <Fragment>
    {getItemDetails({ containerClass, priceLabel, quantity }).map(
      ({ className, label, labelClass, value, valueClassName }, index) =>
        Boolean(value) && (
          <TextKeyValuePair
            key={index}
            {...{
              className,
              label,
              labelClass,
              value,
              valueClassName
            }}
          />
        )
    )}
  </Fragment>
);

export default ItemCommonDetails;

ItemCommonDetails.propTypes = {
  containerClass: PropTypes.string,
  priceLabel: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  quantity: PropTypes.number
};
