import { excludeCharFromString } from '@/lib/utils';
import * as HttpService from '@/services/http.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';
import {
  ADD_HOST,
  GET_CUSTOMER_SEGMENTS,
  GET_HOST_LIST
} from '@/services/url.service';

const getHostListAPI = ({ filters = {}, limit, skip }) =>
  HttpService.getWithAuth(
    GET_HOST_LIST({ filters: JSON.stringify(filters), limit, skip })
  );

export const addHostAPI = (data) => HttpService.postWithAuth(ADD_HOST(), data);

export const getCustomerSegmentListAPI = () =>
  HttpService.getWithAuth(GET_CUSTOMER_SEGMENTS());

export const getHostList = async ({
  setHostList,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  const {
    entity: { rows },
    message,
    status
  } = await getHostListAPI({ limit: 10, skip: 0 });
  if (status) {
    setHostList(rows);
  } else {
    setShowToast({ show: true, message, status });
  }
  setLoading(false);
};

export const searchInputLabel = {
  MOBILE_NUMBER: {
    key: 'mobile',
    label: 'Mobile Number'
  },
  NAME: {
    key: 'name',
    label: 'Name'
  },
  EMAIL: {
    key: 'email',
    label: 'Email'
  },
  HOST_NUMBER: {
    key: 'hostNumber',
    label: 'Host Number'
  }
};

export const defaultFilterParameters = {
  caseSensitive: false,
  comparator: 'LIKE',
  filterType: 'TEXT',
  filterVal: ''
};

const searchHostListAPI = async ({
  dropdownLabelKey,
  searchInput,
  setHostCount,
  setHostList,
  setShowToast
}) => {
  const { status, entity, message } = await getHostListAPI({
    filters: {
      [dropdownLabelKey]: {
        ...defaultFilterParameters,
        filterVal: searchInput
      }
    },
    limit: 10,
    skip: 0
  });
  if (status) {
    const { rows, count } = entity;
    setHostList(rows);
    setHostCount(count);
  } else {
    setHostCount(0);
    setHostList([]);
    setShowToast({
      message,
      show: true,
      status
    });
  }
};

export const searchHostByMobileAPI = async ({ mobile }) => {
  const mobileNumber = excludeCharFromString({ string: mobile, char: '+' });
  const { status, entity } = await getHostListAPI({
    filters: {
      mobile: {
        ...defaultFilterParameters,
        filterVal: mobileNumber
      }
    },
    limit: 1,
    skip: 0
  });
  if (status) {
    const { rows } = entity;
    const [matchingHost] = rows || [];
    return matchingHost;
  }
  return null;
};

export const getCustomerSegmentList = async ({
  setCustomerSegmentList,
  setShowToast
}) => {
  const { entity, message, status } = await getCustomerSegmentListAPI();
  if (status) {
    setCustomerSegmentList(entity);
  } else {
    setShowToast({
      message,
      show: true,
      status: false
    });
  }
};

export const onSubmitCreateNewHostHandler = async ({
  dataToSubmit,
  hostIdWithMatchingMobile,
  router,
  setHostIdWithMatchingMobile,
  setOpenAddHostModal,
  setShowToast
}) => {
  const { codeId, customerName, customerSegmentId, phoneNumber } = dataToSubmit;
  const mobile = `${codeId.code}${phoneNumber}`;
  if (hostIdWithMatchingMobile) return;
  const matchingHost = await searchHostByMobileAPI({ mobile });
  if (matchingHost?.id) {
    setHostIdWithMatchingMobile(matchingHost.id);
    return;
  }
  const { message, status, entity } = await addHostAPI({
    consentForWhatsapp: true,
    customerSegmentId,
    email: `haflacustomer${mobile}@dummy.com`,
    mobile,
    name: customerName,
    socialProfiles: {}
  });

  setShowToast({
    message,
    show: true,
    status
  });
  if (status) {
    router.push(
      getPageURL({
        pageName: PAGE_NAME.HOST_EVENT_LIST.label,
        pathParams: {
          userId: entity.id
        }
      })
    );
  } else {
    setOpenAddHostModal(false);
    router.replace(getPageURL({ pageName: PAGE_NAME.HOST_LIST.label }));
  }
};

export const searchHosts = async ({
  dropdownLabelKey,
  searchInput,
  setHostList,
  setShowToast,
  setHostCount
}) => {
  await searchHostListAPI({
    dropdownLabelKey,
    searchInput,
    setHostList,
    setShowToast,
    setHostCount
  });
};
