import { Modal, ModalBody } from 'reactstrap';

import {
  Button,
  CloseIcon,
  InputField,
  SelectFormInput,
  Text
} from '@/components/atomic/nuclei';

const RequestCreditModal = ({ closeRequestCreditModal }) => (
  <Modal
    backdrop={true}
    centered
    fade={false}
    isOpen={true}
  >
    <ModalBody>
      <div className='flex h-screen justify-center'>
        <div className='self-center relative bg-white px-6 rounded-lg w-[720px]'>
          <div className='flex justify-end pt-4 mb-4 relative left-3 -top-1'>
            <CloseIcon {...{ onClick: closeRequestCreditModal }} />
          </div>
          <Text
            {...{
              className: 'text-xl font-medium text-nero mb-3 text-center',
              content: 'Apply For Credit'
            }}
          />
          <div className='flex flex-col gap-4 mt-4'>
            <div className='flex gap-4'>
              <InputField
                {...{
                  className: 'rounded-lg pl-10',
                  dbName: 'requestAmount',
                  iconURL: 'budget-icon-red.svg',
                  inputGroup: 'flex flex-col gap-2 w-1/2',
                  label: 'Credit Request Amount *',
                  labelClass: 'text-nero text-base font-medium',
                  maxLength: 6,
                  placeholder: 'Enter Credit Amount',
                  register: () => {}
                }}
              />
              <InputField
                {...{
                  className: 'rounded-lg pl-10',
                  dbName: 'days',
                  iconURL: 'trn-icon-red.svg',
                  inputGroup: 'flex flex-col gap-2 w-1/2',
                  label: 'Payment Terms (in days) *',
                  labelClass: 'text-nero text-base font-medium',
                  maxLength: 6,
                  placeholder: '0 - 180 days (payback terms)',
                  register: () => {}
                }}
              />
            </div>
            <SelectFormInput
              {...{
                containerClassName:
                  'relative multi-select-epm epm-dropdown border border-platinum rounded',
                dbName: 'reason',
                errors: {},
                getValues: () => {},
                icon: {
                  url: 'trn-icon-red.svg'
                },
                isClearable: false,
                label: 'Reason for Credit *',
                name: 'accountManager',
                placeholderLabel: 'Reason for Credit',
                register: () => {},
                setValue: () => {},
                showIcon: true,
                showLabel: true,
                values: [{}]
              }}
            />
            {
              // TODO: TJ,HD,SK Will show other field later in next coming tickets
            }
            <div className='hidden'>
              <InputField
                {...{
                  className: 'rounded-lg pl-3',
                  dbName: 'credit',
                  inputGroup: 'flex flex-col gap-2 w-full',
                  labelClass: 'text-nero text-base font-medium',
                  maxLength: 6,
                  placeholder: 'Type reason for credit request',
                  register: () => {}
                }}
              />
            </div>
          </div>
          <Button
            {...{
              className:
                'bg-brand-gradient text-white text-sm font-medium py-3 rounded mt-4 mb-4',
              label: 'Apply'
            }}
          />
        </div>
      </div>
    </ModalBody>
  </Modal>
);

export default RequestCreditModal;
