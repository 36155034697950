import Image from 'next/image';
import { PropTypes } from 'prop-types';

import { staticMediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/lib/screenResolution';

const AlertMessage = ({ message }) => {
  const [isMobile] = useIsMobile();
  return (
    <div className='flex md:gap-1 gap-3 bg-unavailable-error p-4'>
      <Image
        alt='error'
        height={0}
        layout={isMobile ? 'fixed' : 'intrinsic'}
        src={`${staticMediaStoreBaseURL}/icons/failed.svg`}
        style={{
          height: 14,
          width: 14
        }}
        width={0}
      />
      <div className='text-error-base font-medium text-xs flex-1 md:text-sm'>
        {message}
      </div>
    </div>
  );
};

export default AlertMessage;

AlertMessage.propTypes = {
  message: PropTypes.string.isRequired
};
