import Image from 'next/image';
import Link from 'next/link';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { PAGE_NAME, getPageURL } from '@/services/plannerPortal.service';

const socialMediaIconList = [
  {
    alt: 'linked-in',
    icon: 'linked-in.svg',
    url: 'https://www.linkedin.com/company/hafla-official'
  },
  {
    alt: 'youtube',
    icon: 'youtube.svg',
    url: 'https://www.youtube.com/channel/UCaUo41TfdsE8Lr_OCb-modg'
  },
  {
    alt: 'facebook',
    icon: 'facebook.svg',
    url: 'https://www.facebook.com/hafla.Official'
  },
  {
    alt: 'instagram',
    icon: 'instagram.svg',
    url: 'https://www.instagram.com/hafla.official'
  },
  {
    alt: 'tiktok',
    icon: 'tiktok.svg',
    url: 'http://www.tiktok.com/@haflaofficial'
  }
];

const exploreSectionData = [
  {
    href: getPageURL({ pageName: PAGE_NAME.PRODUCT_SEARCH.label }),
    label: 'Product Search'
  }
];

const Heading = ({ label }) => (
  <div>
    <Text
      {...{
        className:
          'text-xs font-medium md:text-lg uppercase text-white mb-3 md:mb-4',
        content: label
      }}
    />

    <div className='w-11 h-0.5 bg-brand-gradient'></div>
  </div>
);

const LinkSection = ({ href, label }) => (
  <li>
    <Link href={href}>
      <Text
        {...{
          className: 'text-white text-xs md:text-base font-light py-1',
          content: label
        }}
      />
    </Link>
  </li>
);

const FooterMenuSection = ({ className = 'w-2/4', data, headingLabel }) => (
  <div className={`${className} md:w-1/4 pt-4 md:pt-0`}>
    <Heading {...{ label: headingLabel }} />
    <ul className='pt-2 md:pt-4'>
      {data.map(({ label, href }, index) => (
        <LinkSection
          key={index}
          {...{ label, href }}
        />
      ))}
    </ul>
  </div>
);

const Footer = () => (
  <footer>
    <div className='bg-nero p-5 md:px-20 md:pt-12 w-full footer-padding-bottom'>
      <div className='flex flex-wrap'>
        <div className='w-full md:w-1/4'>
          <Link
            href={getPageURL({
              pageName: PAGE_NAME.WELCOME.label
            })}
          >
            <Image
              alt='Hafla'
              className='inline-block max-w-17.5 md:max-w-26'
              height={0}
              src={`${staticMediaStoreBaseURL}/icons/hafla.svg`}
              style={{ height: 34, width: 104 }}
              width={0}
            />
          </Link>
          <Text
            {...{
              className:
                'w-full mt-2 md:mt-3 font-semibold text-sm md:text-xl text-white md:text-light-gray',
              content: 'Planner Portal'
            }}
          />

          <div className='md:justify-start pt-10 pb-4 hidden md:block'>
            <ul className='list-none justify-start grid grid-flow-row grid-cols-5  gap-4 w-max'>
              {socialMediaIconList.map(({ url, icon, alt }, index) => (
                <li key={index}>
                  <Link
                    href={url}
                    target='_blank'
                  >
                    <Image
                      alt={alt}
                      height={0}
                      src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                      style={{ height: 30, width: 30 }}
                      width={0}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <FooterMenuSection
          {...{
            className: 'w-2/5',
            data: exploreSectionData,
            headingLabel: 'EXPLORE'
          }}
        />
      </div>
      <div className='justify-center flex md:justify-start pt-6 pb-4 md:hidden'>
        <ul className='list-none justify-start grid grid-flow-row grid-cols-5  gap-4 w-max'>
          {socialMediaIconList.map(({ url, icon, alt }, index) => (
            <li key={index}>
              <Link
                href={url}
                target='_blank'
              >
                <Image
                  alt={alt}
                  height={0}
                  src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                  style={{ height: 30, width: 30 }}
                  width={0}
                />
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className='mt-4 md:mt-10'>
        <Text
          {...{
            className: 'text-base text-dim-gray',
            content: '© ',
            HtmlTag: 'span'
          }}
        />
        <Text
          {...{
            className:
              'text-xs md:text-sx font-medium text-center md:text-left text-dim-gray',
            content: 'Copyright 2024 Hafla. All rights reserved.',
            HtmlTag: 'span'
          }}
        />
      </div>
    </div>
  </footer>
);

export default Footer;
