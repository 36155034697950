const COMMENT_LENGTH = 100;

const ShowMoreLessCTA = ({ className, setShowMore, showMore, textValue }) => (
  <div className={`flex self-center ${className}`}>
    {!textValue && <span className='text-center pl-2 font-bold'>-</span>}
    <span>
      {!showMore ? textValue : textValue?.slice(0, COMMENT_LENGTH)}
      <span
        className='cursor-pointer text-xs font-medium text-brand px-2'
        onClick={(e) => {
          e.stopPropagation();
          setShowMore(!showMore);
        }}
      >
        {textValue?.length > COMMENT_LENGTH &&
          (!showMore ? 'Show Less' : 'Show More')}
      </span>
    </span>
  </div>
);

export default ShowMoreLessCTA;
