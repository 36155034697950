import Image from 'next/image';

import {
  RichTextEditor,
  Text,
  TextKeyValuePair
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { convertToDateTimeString } from '@/lib/time';

const ExternalNotes = ({ externalNotes }) => (
  <div className='flex gap-2'>
    <div className='flex w-5 h-5'>
      <Image
        alt='note-icon'
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/note-icon-with-frame.svg`}
        style={{ height: 24, width: 24 }}
        width={0}
      />
    </div>
    <div className='flex-col flex-1'>
      <Text
        className='uppercase text-xs font-medium'
        content='Notes'
      />
      <RichTextEditor
        {...{
          readOnly: true,
          values: externalNotes
        }}
      />
    </div>
  </div>
);

const DeliveryInfoMobile = ({
  deliveryDate,
  deliveryTime,
  externalNotes,
  headerLabel = 'Delivery Info',
  pickupDate,
  pickupTime
}) => {
  const isDeliveryInfoVisible = deliveryDate || pickupDate || externalNotes;
  const deliveryDateTime = {
    date: deliveryDate,
    time: deliveryTime
  };
  const pickupDateTime = {
    date: pickupDate,
    time: pickupTime
  };
  const deliveryInfo = [
    {
      alt: 'delivery-icon',
      icon: 'delivery-truck.svg',
      label: 'Delivery Date',
      value: deliveryDate ? convertToDateTimeString(deliveryDateTime) : false
    },
    {
      alt: 'pickup-icon',
      icon: 'pickup-truck.svg',
      label: 'Pickup Date',
      value: pickupDate ? convertToDateTimeString(pickupDateTime) : false
    }
  ];

  return (
    isDeliveryInfoVisible && (
      <div className='block md:hidden border border-platinum bg-lightgray p-4 md:p-6 rounded-md'>
        <Text
          {...{
            content: headerLabel,
            className: 'font-medium text-base mb-4'
          }}
        />
        <div className='flex flex-col gap-3'>
          {deliveryInfo.map(
            ({ alt, icon, label, value }, index) =>
              value && (
                <div
                  key={index}
                  className='flex gap-2'
                >
                  <div className='flex w-5 h-5'>
                    <Image
                      alt={alt}
                      height={0}
                      src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                      style={{ height: 24, width: 24 }}
                      width={0}
                    />
                  </div>
                  <TextKeyValuePair
                    {...{
                      className: 'flex-col flex-1',
                      label,
                      labelClass: 'uppercase text-xs font-medium',
                      spaceTop: '',
                      value,
                      valueClassName: 'text-sm font-light'
                    }}
                  />
                </div>
              )
          )}
          <ExternalNotes {...{ externalNotes }} />
        </div>
      </div>
    )
  );
};

export default DeliveryInfoMobile;
