import { isBrowser } from 'lib/utils';
import { isAuthenticated } from 'services/identity.service';
import { getOpsPortalLoginURL } from 'services/opsPortal.service';
import isProtectedRoute from 'services/security.service';

const WithAuth = ({ router, children }) => {
  if (isBrowser()) {
    if (!isAuthenticated() && isProtectedRoute(router.pathname)) {
      router.replace({
        pathname: getOpsPortalLoginURL(),
        query: { redirectTo: router.asPath }
      });
    }
  }

  return children;
};

export default WithAuth;
