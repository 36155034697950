import Image from 'next/image';

import { Text } from '@/components/atomic/nuclei';
import { getImageURL } from '@/helpers/carousel';

const IMAGE_GALLERY_ROW_COUNT = 6;

const UserEventMedia = ({
  userEventMediaList,
  openImageModal,
  setShowImageGalleryModal
}) => (
  <div>
    <div className='flex justify-between  my-6'>
      <div className='self-center flex gap-5'>
        <Text
          {...{
            content: 'Event Media',
            className:
              'text-nero text-base md:text-2xl font-medium font-Montserrat flex self-center'
          }}
        />
      </div>
      <div className='flex'>
        <Text
          {...{
            className: 'text-dim-gray text-sm font-medium cursor-pointer',
            content: 'View All',
            onClick: () => setShowImageGalleryModal(true)
          }}
        />
      </div>
    </div>
    <div
      className='py-5 my-5 md:border text-sm md:text-base
    border-platinum rounded-lg px-4 md:px-6 bg-lightgray
    font-medium  shadow-event cursor-pointer'
    >
      <div className='flex gap-3'>
        {userEventMediaList
          .slice(0, IMAGE_GALLERY_ROW_COUNT)
          .map(({ source, url }, index) => (
            <a
              className='w-30 h-30 rounded-2.5'
              key={index}
              onClick={() => openImageModal(userEventMediaList[index])}
            >
              <Image
                alt='userevent-media'
                className='rounded-2.5'
                height={0}
                id='user-event-order-media'
                src={getImageURL({ source, url })}
                style={{ height: 120, width: 120 }}
                width={0}
              />
            </a>
          ))}
      </div>
    </div>
  </div>
);

export default UserEventMedia;
