import { GoogleTagManager } from '@next/third-parties/google';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from 'next-i18next.config';
import { DefaultSeo } from 'next-seo';
import Head from 'next/head';

import { SendbirdChatBot } from 'components/atomic/atoms';
import WithAuth from 'components/auth/WithAuth';
import { ManagedUIContext } from 'components/context/UiContext/ManagedUiContext';
import RecordPageViewEvent from 'components/general/RecordPageViewEvent';
import { GetUiDeviceSurface } from 'lib/screenResolution';
import NEXT_SEO_CONFIG from 'next-seo.config';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import 'instantsearch.css/themes/satellite.css';
import 'material-icons/iconfont/material-icons.css';
import 'rc-slider/assets/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'styles/globals.css';

const gtmId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
const gtmAuth = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH;
const gtmPreview = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_PREVIEW;

const DOMContextProvider = ({ children }) => {
  const surface = GetUiDeviceSurface();
  return <div className={`surface-${surface}`}>{children}</div>;
};

const Base = ({ Component, pageProps, router }) => (
  <WithAuth router={router}>
    <ManagedUIContext>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0'
        />
      </Head>
      <DOMContextProvider>
        <GoogleTagManager
          auth={gtmAuth}
          dataLayerName='dataLayer'
          gtmId={gtmId}
          preview={gtmPreview}
        />
        <RecordPageViewEvent />
        <DefaultSeo {...NEXT_SEO_CONFIG} />
        <SendbirdChatBot />
        <Component {...pageProps} />
      </DOMContextProvider>
    </ManagedUIContext>
  </WithAuth>
);

const MyApp = (props) => <Base {...props} />;

export default appWithTranslation(MyApp, nextI18NextConfig);
