import ReactProgressBar from '@ramonak/react-progress-bar';

const ProgressBar = ({ progressBarPercentage }) => (
  <div className='w-full event-profile-progress-bar'>
    <ReactProgressBar
      {...{
        bgColor: '#222222',
        completed: progressBarPercentage,
        customLabel: `${progressBarPercentage} %`,
        customLabelStyles: { marginRight: 8 },
        labelAlignment: 'right',
        labelSize: '12.8px'
      }}
    />
  </div>
);
export default ProgressBar;
