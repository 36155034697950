import { useState } from 'react';

import { ShowMoreLessCTA, Text } from '@/components/atomic/nuclei';
import { BID_ITEM_STATUS } from '@/config/common';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';
import { tenderItemBidderHeaderConfig } from '@/services/tenderResponse.service';

const COMMENT_LENGTH = 100;

const BidderHeader = () => (
  <div className='flex border border-b-0 bg-white justify-between  py-5 px-4 text-10px font-semibold uppercase rounded-t-2.5'>
    {tenderItemBidderHeaderConfig.map(({ className, content }, id) => (
      <Text
        key={id}
        {...{ className, content }}
      />
    ))}
  </div>
);

const BidderDetail = ({
  isLastItem,
  partnerName,
  partnerNotes,
  quantityRequested,
  status,
  unitPartnerPriceOffered
}) => {
  const comment = status !== BID_ITEM_STATUS.PENDING.value && partnerNotes;
  const [showMore, setShowMore] = useState(comment?.length > COMMENT_LENGTH);
  const partnerPriceOfferedStringified = String(
    parseFormatPriceValueFromAPI(unitPartnerPriceOffered * quantityRequested)
  );
  return (
    <div
      className={`flex ${
        isLastItem ? 'rounded-b-2.5' : ''
      } border bg-white text-nero justify-between py-6 border-t border-b px-4 text-xs  self-center`}
    >
      <Text
        {...{
          className: 'w-60 flex self-center',
          content: partnerName || '-'
        }}
      />
      <div className='w-32'>
        <div className='flex gap-2'>
          <div
            className={`w-2 h-3 flex self-center rounded ${BID_ITEM_STATUS[status]?.className}`}
          ></div>
          <Text
            {...{
              className: 'flex self-center',
              content: BID_ITEM_STATUS[status]?.label
            }}
          />
        </div>
      </div>
      <Text
        {...{
          className: 'w-32 flex self-center',
          content:
            status !== BID_ITEM_STATUS.PENDING.value
              ? partnerPriceOfferedStringified
              : '-'
        }}
      />
      <ShowMoreLessCTA
        {...{
          className: 'w-150',
          setShowMore,
          showMore,
          textValue: comment
        }}
      />
    </div>
  );
};

const TenderItemResponse = ({ productBidInfoList, isEvenRow }) => {
  const rowCount = productBidInfoList.length;
  return (
    <div
      className={`${
        isEvenRow ? 'bg-platinum' : 'bg-white'
      } border-l border-r border-b text-silver p-6`}
    >
      <BidderHeader />
      {productBidInfoList.map((item, index) => (
        <BidderDetail
          key={index}
          {...{ ...item, isLastItem: index + 1 === rowCount }}
        />
      ))}
    </div>
  );
};

export default TenderItemResponse;
