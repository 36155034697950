import Image from 'next/image';
import { memo, useState } from 'react';

import { staticMediaStoreBaseURL } from '@/config/common';

const DefaultTextWithIcon = ({
  alt,
  className,
  icon,
  iconHeight,
  iconStyle,
  iconWidth,
  id,
  label,
  labelStyle,
  suffixIcon,
  suffixIconAlt,
  suffixIconHeight,
  suffixIconStyle,
  suffixIconWidth
}) => (
  <div className={`flex gap-2 items-center ${className}`}>
    <Image
      alt={alt}
      className={iconStyle}
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/${icon}`}
      style={{
        height: iconHeight,
        width: iconWidth
      }}
      width={0}
    />
    <div className={labelStyle}>{label}</div>
    {suffixIcon && (
      <Image
        id={id}
        alt={suffixIconAlt}
        className={suffixIconStyle}
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/${suffixIcon}`}
        style={{
          height: suffixIconHeight,
          width: suffixIconWidth
        }}
        width={0}
      />
    )}
  </div>
);

const HoveredTextWithIcon = ({
  className,
  hoverPrefixIcon,
  hoverPrefixIconAlt,
  hoverPrefixIconHeight,
  hoverPrefixIconStyle,
  hoverPrefixIconWidth,
  hoverSuffixIcon,
  hoverSuffixIconAlt,
  hoverSuffixIconHeight,
  hoverSuffixIconStyle,
  hoverSuffixIconWidth,
  id,
  style,
  text
}) => (
  <div className={`flex gap-2 items-center ${className}`}>
    <Image
      alt={hoverPrefixIconAlt}
      className={hoverPrefixIconStyle}
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/${hoverPrefixIcon}`}
      style={{
        height: hoverPrefixIconHeight,
        width: hoverPrefixIconWidth
      }}
      width={0}
    />
    <div className={style}>{text}</div>
    {hoverSuffixIcon && (
      <Image
        id={id}
        alt={hoverSuffixIconAlt}
        className={hoverSuffixIconStyle}
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/${hoverSuffixIcon}`}
        style={{
          height: hoverSuffixIconHeight,
          width: hoverSuffixIconWidth
        }}
        width={0}
      />
    )}
  </div>
);

const TextWithIcon = memo(
  ({
    alt = 'icon',
    className = 'shadow-card py-1 px-2 rounded',
    icon,
    iconHeight = 16,
    iconStyle,
    iconWidth = 16,
    iconSuffix: {
      id,
      alt: suffixIconAlt,
      icon: suffixIcon,
      iconHeight: suffixIconHeight,
      iconStyle: suffixIconStyle,
      iconWidth: suffixIconWidth
    } = {},
    label,
    labelStyle = 'text-sm text-nero',
    onClick = () => {},
    onHover,
    show = true
  }) => {
    const {
      label: { text, style } = {},
      iconPrefix: {
        alt: hoverPrefixIconAlt,
        icon: hoverPrefixIcon,
        iconHeight: hoverPrefixIconHeight,
        iconStyle: hoverPrefixIconStyle,
        iconWidth: hoverPrefixIconWidth
      } = {},
      iconSuffix: {
        alt: hoverSuffixIconAlt,
        icon: hoverSuffixIcon,
        iconHeight: hoverSuffixIconHeight,
        iconStyle: hoverSuffixIconStyle,
        iconWidth: hoverSuffixIconWidth
      } = {}
    } = onHover || {};

    const [isHovered, setIsHovered] = useState(false);
    return (
      Boolean(show) && (
        <div
          onClick={onClick}
          onMouseEnter={() => Boolean(onHover) && setIsHovered(true)}
          onMouseLeave={() => Boolean(onHover) && setIsHovered(false)}
        >
          {isHovered ? (
            <HoveredTextWithIcon
              {...{
                className,
                hoverPrefixIcon,
                hoverPrefixIconAlt,
                hoverPrefixIconHeight,
                hoverPrefixIconStyle,
                hoverPrefixIconWidth,
                hoverSuffixIcon,
                hoverSuffixIconAlt,
                hoverSuffixIconHeight,
                hoverSuffixIconStyle,
                hoverSuffixIconWidth,
                id,
                style,
                text
              }}
            />
          ) : (
            <DefaultTextWithIcon
              {...{
                alt,
                className,
                icon,
                iconHeight,
                iconStyle,
                iconWidth,
                id,
                label,
                labelStyle,
                suffixIcon,
                suffixIconAlt,
                suffixIconHeight,
                suffixIconStyle,
                suffixIconWidth
              }}
            />
          )}
        </div>
      )
    );
  }
);

TextWithIcon.displayName = 'TextWithIcon';

export default TextWithIcon;
