import { HOST_PORTAL_URL } from '@/services/connections.service';

export const getProductDetailPageURL = ({ slug }) =>
  `${HOST_PORTAL_URL}/products/${slug}`;

export const getWebQuotePageURL = ({ cartId }) =>
  `${HOST_PORTAL_URL}/host-carts/${cartId}/quote`;

export const getPaymentPageLink = () => `${HOST_PORTAL_URL}/payments`;

export const getTermsAndConditionsURL = () =>
  `${HOST_PORTAL_URL}/terms-and-conditions`;
