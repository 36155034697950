import Image from 'next/image';
import { useRef, useState } from 'react';

import { InputField, Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { searchInputLabel } from '@/services/hostList.service';

const DropdownList = ({
  openHostFilterDropdown,
  selectSearchableLabel,
  setOpenHostFilterDropdown,
  setSelectedSearchableLabel
}) =>
  openHostFilterDropdown && (
    <div className='cursor-pointer flex flex-col gap-1 text-dim-gray font-medium rounded-lg absolute top-14 z-10 bg-white shadow w-52'>
      {Object.values(searchInputLabel).map(
        ({ key, label }) =>
          selectSearchableLabel.key !== key && (
            <Text
              key={`${key} - ${label} - ${Date.now()}`}
              {...{
                className:
                  'hover:bg-brand-gradient px-4 py-2 cursor-pointer hover:text-white',
                content: label,
                onClick: (e) => {
                  e.preventDefault();
                  setSelectedSearchableLabel({ key, label });
                  setOpenHostFilterDropdown(false);
                }
              }}
            />
          )
      )}
    </div>
  );

const SearchHostInput = ({
  searchInputValue,
  selectSearchableLabel,
  setSearchInputValue,
  setSelectedSearchableLabel
}) => {
  const [openHostFilterDropdown, setOpenHostFilterDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useOnClickOutside(dropdownRef, () => setOpenHostFilterDropdown(false));

  return (
    <div
      ref={dropdownRef}
      className='flex relative'
    >
      <div
        className='cursor-pointer bg-brand-gradient text-white shadow rounded-l-full pl-6 pr-2 flex gap-4 justify-center items-center font-medium'
        onClick={() => setOpenHostFilterDropdown(!openHostFilterDropdown)}
      >
        <Text {...{ content: selectSearchableLabel.label }} />
        <Image
          {...{
            alt: 'down angle',
            className: `transform ${openHostFilterDropdown ? 'rotate-180' : ''}`,
            height: 0,
            src: `${staticMediaStoreBaseURL}/icons/down-angle-white.svg`,
            style: { height: 16, width: 16 },
            width: 0
          }}
        />
      </div>
      <div className='relative'>
        <InputField
          {...{
            baseClass:
              'border text-gray-900 px-2 py-2 outline-none placeholder:text-grey-400 shadow',
            className: 'rounded-r-full h-12 w-128 pl-10',
            iconClass: '!top-3',
            iconURL: 'search-red.svg',
            onChange: (e) => setSearchInputValue(e.target.value),
            placeholder: 'Search...',
            value: searchInputValue
          }}
        />
        {searchInputValue?.length > 0 && (
          <Image
            {...{
              alt: 'close-neutral-icon',
              className: 'absolute top-[17px] left-full -ml-10',
              height: 0,
              onClick: () => setSearchInputValue(''),
              src: `${staticMediaStoreBaseURL}/icons/close-neutral-icon.svg`,
              style: { width: 12, height: 12 },
              width: 0
            }}
          />
        )}
      </div>
      <DropdownList
        {...{
          openHostFilterDropdown,
          selectSearchableLabel,
          setOpenHostFilterDropdown,
          setSelectedSearchableLabel
        }}
      />
    </div>
  );
};
export default SearchHostInput;
