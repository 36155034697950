import Image from 'next/image';
import { PropTypes } from 'prop-types';

import { staticMediaStoreBaseURL } from '@/config/common';

const BottomModal = ({ children, paramData }) => {
  const { showHeader, handler, label, modalHeight, setCloseModal, hideClose } =
    paramData;
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        if (e.target.id === 'modal') {
          setCloseModal(false);
        }
      }}
      id='modal'
      className={
        'fixed z-101 w-full h-full inset-0 bg-nero bg-opacity-80 transition-opacity flex items-end top-0 left-0'
      }
    >
      <div
        className={`z-120 bg-white w-full md:max-w-152 md:absolute top-20 toaster left-1/2 ${
          modalHeight || 'min-h-96'
        } mx-auto md:rounded-lg`}
      >
        {showHeader ? (
          <div
            className='z-50 text-black relative flex justify-between p-5 pb-4 md:p-8 md:pb-8'
            onClick={() => handler()}
          >
            <h5 className='font-medium font-Montserrat text-base leading-5 text-nero'>
              {' '}
              {label}{' '}
            </h5>
            {!hideClose && (
              <div className='border border-gray box-border flex justify-center bg-white rounded-full w-4 h-4'>
                <Image
                  alt='Close Arrow'
                  className='w-full mx-auto max-w-1.5'
                  height={0}
                  src={`${staticMediaStoreBaseURL}/icons/close-dark.svg`}
                  style={{ height: 12, width: 12 }}
                  width={0}
                />
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        {children}
      </div>
    </div>
  );
};

export default BottomModal;

BottomModal.propTypes = {
  children: PropTypes.any.isRequired,
  paramData: PropTypes.any
};
