import { InputField, Text } from '@/components/atomic/nuclei';
import { VIEW_EDIT_MODE } from '@/config/cart';

const ViewSummary = ({ haflaPrice }) => (
  <Text {...{ className: 'w-40 self-center', content: String(haflaPrice) }} />
);

const EditSummary = ({ register, rowTitle, dbName, getValues }) => (
  <InputField
    {...{
      baseClass: 'border px-2 py-1 text-nero text-sm',
      className: 'rounded-lg w-20',
      dbName: dbName || rowTitle,
      defaultValue: getValues(dbName) || 0,
      inputGroup: 'relative -left-5',
      register: register(dbName)
    }}
  />
);
const ViewEditSummaryMode = {
  EDIT: EditSummary,
  VIEW: ViewSummary
};

const PriceSummaryRow = ({
  absoluteMargin,
  dbName,
  getValues,
  haflaPrice,
  margin,
  mode = VIEW_EDIT_MODE.VIEW,
  partnerPrice,
  register,
  rowTitle
}) => {
  const Summary = ViewEditSummaryMode[mode];
  const marginString =
    margin === '-' || Number(margin) === 0 ? margin : `${margin} %`;

  return (
    <div className='w-full'>
      <div className='flex gap-5 text-sm text-nero font-light py-3 px-4 bg-lightgray'>
        <div className='w-108 flex gap-3 self-center'>
          <Text
            {...{
              className: 'self-center flex-1 flex',
              content: rowTitle
            }}
          />
        </div>
        <Summary {...{ rowTitle, haflaPrice, register, dbName, getValues }} />
        <Text
          {...{ content: String(partnerPrice), className: 'w-40 self-center' }}
        />
        <Text
          {...{
            content: String(absoluteMargin),
            className: 'w-56 self-center'
          }}
        />
        <Text
          {...{
            className: 'w-40 self-center',
            content: marginString
          }}
        />
      </div>
    </div>
  );
};

export default PriceSummaryRow;
