import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';

const Loader = () => (
  <div className='loading-bg h-screen flex'>
    <Image
      alt='loading image'
      className='self-center mx-auto w-12'
      height='0'
      priority={true}
      src={`${staticMediaStoreBaseURL}/icons/loading.gif`}
      style={{ height: 20, width: 20 }}
      width='0'
    />
  </div>
);

export default Loader;
