const FRACTIONAL_PRECISION = 100;

export const convertToCurrencyFormat = ({
  value,
  withCurrencyPrefix = true
}) => {
  const currencyFormatWithPrefix = {
    currency: 'AED',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency'
  };
  const currencyFormatWithoutPrefix = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'decimal'
  };
  const options = withCurrencyPrefix
    ? currencyFormatWithPrefix
    : currencyFormatWithoutPrefix;
  return new Intl.NumberFormat('en-IN', options).format(value);
};

const convertToUpperDenomination = ({
  formatDecimalPrecision = 2,
  returnType = 'float',
  value = 0
}) => {
  const parsedValue = Number(value);

  if (Number.isNaN(parsedValue)) {
    throw new Error(
      `unable to parse the value ${value}, parsing it results in NaN`
    );
  }

  const upperDenominationValue = parseFloat(parsedValue) / FRACTIONAL_PRECISION;

  switch (returnType.toLowerCase()) {
    case 'float':
      return parseFloat(upperDenominationValue.toFixed(formatDecimalPrecision));
    case 'string':
      return parseFloat(upperDenominationValue).toFixed(formatDecimalPrecision);
    default:
      throw new Error('unknown returnType');
  }
};

export const convertToLowerDenomination = (value) =>
  Math.round(value * FRACTIONAL_PRECISION);

export const getFloatStringToTwoDecimalPlaces = (value) =>
  parseFloat(value).toFixed(2);

const getFloatStringWithZeroFractionTrimmed = (floatString) => {
  const [number, fraction] = floatString.split('.');
  return Math.round(fraction) === 0 ? number : floatString;
};

export const parsePriceValueForAPI = (price) =>
  Math.round(parseFloat(convertToLowerDenomination(price)).toFixed(2));

export const parseFormatPriceValueFromAPI = (value = 0) =>
  convertToUpperDenomination({
    value,
    returnType: 'string'
  });

export const parseFormatPriceNumberValueFromAPI = (value) =>
  convertToUpperDenomination({
    value,
    returnType: 'float'
  });

export const decorateNumberForDisplay = (value) =>
  getFloatStringToTwoDecimalPlaces(value);

export const calculatePercent = (value, total) => (value * 100) / total;

export const calculateMarginPercent = (value, total) =>
  ((total - value) * 100) / total;

export const parseFormatPercentValueFromAPI = (value) =>
  convertToUpperDenomination({
    value,
    returnType: 'string'
  });

export const formatDiscount = (value) => convertToUpperDenomination({ value });

export const formatDiscountObjectToString = ({ value, type }) => {
  let prefix = '';
  let suffix = '';
  if (type === 'Percentage') {
    prefix = '';
    suffix = ' % off';
  }
  if (type === 'Absolute') {
    prefix = 'Flat ';
    suffix = ' off';
  }

  return `${prefix}${convertToUpperDenomination({
    value,
    returnType: 'string'
  })}${suffix}`;
};

// TODO: Sidd - after host-cart-v1 try to deprecate this function
export const formatToTwoDecimalString = ({
  value,
  convertValueToUpperDenomination = true,
  trimZeroFraction = true
}) => {
  const parsedValue = Number(value);

  if (Number.isNaN(parsedValue)) {
    throw new Error(
      `unable to parse the value ${value}, parsing it results in NaN`
    );
  }

  const valueToProcess = convertValueToUpperDenomination
    ? convertToUpperDenomination({ value: parsedValue })
    : value;

  const floatStringToTwoDecimalPlaces =
    getFloatStringToTwoDecimalPlaces(valueToProcess);

  return trimZeroFraction
    ? getFloatStringWithZeroFractionTrimmed(floatStringToTwoDecimalPlaces)
    : floatStringToTwoDecimalPlaces;
};
