import _ from 'lodash';

import { COOKIE_MAP, getCookie, removeCookie } from 'services/cookie.service';

// TODO: alter the function names below to indicate that data contain identity and auth token
export const getAuth = () => {
  const auth = getCookie({ name: COOKIE_MAP.OPS_PORTAL_USER_IDENTITY });
  if (auth?.token) {
    return auth;
  }
  return null;
};

export const removeAuth = () =>
  removeCookie({ name: COOKIE_MAP.OPS_PORTAL_USER_IDENTITY });

export const getAuthToken = () => getAuth()?.token || null;

export const isAuthenticated = () => {
  const token = getAuthToken();
  return Boolean(!_.isEmpty(token));
};

export const getPlannerName = () => getAuth()?.name;
export const getPlannerId = () => getAuth()?.id;

// TODO: will use when planner portal will have its own auth implementation - following changes are to be done
/*
export const setAuth = (authObject) => {
  return cookies.set(COOKIE_MAP.PLANNER_PORTAL_AUTH, JSON.stringify(authObject));
};
*/
/*
  find and replace - manage via uiContext
  const [plannerName, setPlannerName] = useState(null);
  useEffect(() => {
    setPlannerName(getPlannerName());
  }, []);
*/
