import Image from 'next/image';
import Link from 'next/link';

import { staticMediaStoreBaseURL } from '@/config/common';
import { getViewProductOrderHistoryURL } from '@/services/cart.plan.service';

const CartItemCommonCTA = ({
  alt = '',
  className = '',
  imgIcon = '',
  onClick
}) => (
  <div
    className={`flex w-6 h-6 items-center bg-neutral
    rounded-full justify-center ${className}`}
  >
    <Image
      {...{
        alt,
        className: 'cursor-pointer',
        height: 0,
        onClick,
        src: `${staticMediaStoreBaseURL}${imgIcon}`,
        style: { height: 26, width: 26 },
        width: 0
      }}
    />
  </div>
);

const ViewCartItemInOpsPortalCTA = ({ additionalProps, className }) => {
  const { productId } = additionalProps;
  return (
    <div className={className}>
      <Link
        href={`${getViewProductOrderHistoryURL({ productId })}`}
        target='_blank'
      >
        <div className='clickable'>
          <Image
            alt='view item detail'
            height={0}
            src={`${staticMediaStoreBaseURL}/icons/hafla-round-icon.svg`}
            style={{ height: 24, width: 24 }}
            width={0}
          />
        </div>
      </Link>
    </div>
  );
};

const CTAProps = {
  delete: {
    alt: 'delete item',
    imgIcon: '/icons/delete-icon-with-gray-frame.svg'
  },
  cloneCartItem: {
    alt: 'clone cart item',
    imgIcon: '/icons/clone-rounded-icon.svg'
  },
  uploadImage: { imgIcon: '/icons/upload-img-icon.svg', alt: 'upload image' },
  viewEditDetail: {
    alt: 'edit item',
    imgIcon: '/icons/view-red-with-gray-frame.svg'
  }
};

const defaultOnClick = () => {};

const CartItemCTA = ({
  additionalProps = {},
  className,
  onClick = defaultOnClick,
  type
}) => {
  const CTAPropsByType = CTAProps[type];
  const CTA =
    type === 'viewInOps' ? ViewCartItemInOpsPortalCTA : CartItemCommonCTA;
  return (
    <CTA
      {...{
        additionalProps,
        onClick,
        className,
        ...CTAPropsByType
      }}
    />
  );
};

export default CartItemCTA;
