import { PropTypes } from 'prop-types';

import { Text } from '@/components/atomic/nuclei';

const ToastMessage = ({ toastMessage, setToastMessage }) => {
  setTimeout(() => setToastMessage(''), 15000);
  return (
    <div className='w-full justify-center my-3'>
      <Text
        {...{
          className:
            'p-2 rounded font-medium text-sm md:text-sm text-white bg-orange transition-all ease-in-out duration-300',
          content: toastMessage
        }}
      />
    </div>
  );
};

export default ToastMessage;

ToastMessage.propTypes = {
  toastMessage: PropTypes.string.isRequired,
  setToastMessage: PropTypes.func
};
