import { TAG_OPTIONS_SELECTION_MODE } from '@/config/common';

const getClassNames = ({
  id,
  isEditable,
  selectedTagOptions,
  tagOptionsSelectionMode
}) => {
  const isSelected =
    tagOptionsSelectionMode === TAG_OPTIONS_SELECTION_MODE.SINGLE
      ? selectedTagOptions === id
      : selectedTagOptions.includes(id);

  const selectionClasses = isSelected
    ? 'selected border-brand text-nero'
    : 'border-platinum text-dim-gray';

  return `rounded-lg border p-3 font-medium
    ${isEditable ? 'hover:border-brand' : ''}
    ${selectionClasses}`;
};

const SelectTags = ({
  containerStyle = 'flex-col gap-2',
  isEditable = false,
  selectedTagOptions,
  setSelectedTagOptions,
  tagOptions,
  tagOptionsSelectionMode = TAG_OPTIONS_SELECTION_MODE.MULTIPLE,
  tagsFieldLabel: { label, labelStyle = 'text-base font-medium' } = {}
}) => {
  const toggleTagSelection = (tag) => {
    if (tagOptionsSelectionMode === TAG_OPTIONS_SELECTION_MODE.SINGLE) {
      setSelectedTagOptions(tag);
      return;
    }
    setSelectedTagOptions((prev) =>
      prev.includes(tag) ? prev.filter((v) => v !== tag) : [...prev, tag]
    );
  };

  const showLabel = Boolean(label);
  return (
    <div className={`flex ${containerStyle}`}>
      {showLabel && <label className={`${labelStyle}`}>{label}</label>}
      <div className='flex gap-4 flex-wrap'>
        {tagOptions.map(({ id, name }) => (
          <button
            key={id}
            className={getClassNames({
              id,
              isEditable,
              selectedTagOptions,
              tagOptionsSelectionMode
            })}
            onClick={() => isEditable && toggleTagSelection(id)}
          >
            {name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectTags;
