import Image from 'next/image';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const PageNotFound = ({
  alt = '404 image',
  className = 'h-screen',
  description = '',
  iconWidth = 260,
  iconHeight = 260,
  notFoundImage = '404.svg',
  title = 'Oops! This page is not found'
}) => (
  <div className={`flex justify-center gap-3 ${className} text-center`}>
    <div className='self-center'>
      <Image
        alt={alt}
        className='mx-auto'
        height={0}
        priority={true}
        src={`${staticMediaStoreBaseURL}/icons/${notFoundImage}`}
        style={{ height: iconHeight, width: iconWidth }}
        width={0}
      />
      <Text
        className='text-2xl font-medium mt-5 text-chinese-black'
        content={title}
      />
      <Text
        className='text-base font-light mt-1 text-chinese-black'
        content={description}
      />
    </div>
  </div>
);

export default PageNotFound;
