import { Text, TextWithIcon } from '@/components/atomic/nuclei';

const InfoMessage = ({ message, top, width }) => (
  <div className='relative flex'>
    <TextWithIcon
      {...{
        className: 'shadow-none',
        icon: 'info-icon.svg',
        iconHeight: 16,
        iconWidth: 16,
        label: message,
        labelStyle: `text-xs book-order-info-message font-light text-nero bg-error-tool-tip absolute ${width} p-3 rounded-xl z-10 left-10 ${top} tooltip`
      }}
    />
  </div>
);

const SectionHeading = ({
  className,
  label,
  message = 'test',
  top = '-top-3',
  width = 'w-100'
}) => (
  <div className='flex gap-1 relative'>
    <Text
      {...{
        className: `text-base font-medium self-center ${className}`,
        content: label
      }}
    />
    {message && <InfoMessage {...{ top, width, message }} />}
  </div>
);

export default SectionHeading;
