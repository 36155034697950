import _ from 'lodash';
import { createContext, useContext, useState } from 'react';

import {
  COOKIE_MAP,
  getCookie,
  removeCookie,
  setCookie
} from '@/services/cookie.service';

const UIContext = createContext();

const useUIContext = () => {
  const context = useContext(UIContext);
  if (context === undefined) {
    throw new Error('useUIContext must be used within a UIProvider');
  }
  return context;
};

const UIProvider = (props) => {
  const [activePlannerCart, setActivePlannerCart] = useState(
    getCookie({
      name: COOKIE_MAP.ACTIVE_PLANNER_CART
    })
  );

  const updateActivePlannerCart = (cartDetail) => {
    if (_.isEmpty(cartDetail)) {
      removeCookie({ name: COOKIE_MAP.ACTIVE_PLANNER_CART });
      setActivePlannerCart(null);
    } else {
      setActivePlannerCart(cartDetail);
      setCookie({
        name: COOKIE_MAP.ACTIVE_PLANNER_CART,
        data: cartDetail
      });
    }
  };

  const value = { activePlannerCart, updateActivePlannerCart };

  return (
    <UIContext.Provider
      value={value}
      {...props}
    />
  );
};

const ManagedUIContext = ({ children }) => <UIProvider>{children}</UIProvider>;

export { ManagedUIContext, useUIContext };
