import Image from 'next/image';

import { Button, Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const AddUserEventBanner = () => (
  <div className='bg-white h-full w-full flex justify-center'>
    <div className='flex flex-col items-center self-center'>
      <Image
        alt='event not found'
        className='mx-auto'
        height={0}
        priority={true}
        src={`${staticMediaStoreBaseURL}/icons/event-not-found.svg`}
        style={{ height: 320, width: 322 }}
        width={0}
      />
      <Text
        {...{
          className: 'text-rich-black font-light mb-3 mt-6',
          content: 'Click to add New Event'
        }}
      />
      <Button
        {...{
          className: 'text-brand font-medium text-sm',
          disabled: true,
          label: '+ Add New Event'
        }}
      />
    </div>
  </div>
);

export default AddUserEventBanner;
