import Image from 'next/image';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const UnSavedChangesErrorMessage = ({ unSavedChangesErrorMessage }) =>
  unSavedChangesErrorMessage && (
    <div className='flex gap-2 -mt-1'>
      <Image
        {...{
          alt: 'error',
          className: 'self-center',
          height: 0,
          src: `${staticMediaStoreBaseURL}/icons/failed.svg`,
          style: { height: 16, width: 16 },
          width: 0
        }}
      />
      <Text
        {...{
          content: unSavedChangesErrorMessage,
          className: 'text-xs font-medium text-error-base self-center'
        }}
      />
    </div>
  );

export default UnSavedChangesErrorMessage;
