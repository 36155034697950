import {
  Text,
  TextKeyValuePair,
  TextWithIcon
} from '@/components/atomic/nuclei';
import { CART_STATUS, DEVICE_SURFACE, ORDER_STATUS } from '@/config/common';
import { GetUiDeviceSurface } from '@/lib/screenResolution';
import { parseNumberedLabel } from '@/lib/utils';

const CartCardHeaderWeb = ({
  cancelOrderClass,
  cartCardTitle,
  cartNumber,
  cartStatusClass,
  isOrderInCancelledState,
  status
}) => (
  <div className={`flex justify-between text-sm ${cancelOrderClass}`}>
    <div className='flex flex-col'>
      <Text
        {...{
          content: cartCardTitle,
          className: `text-sm md:text-lg overflow-ellipsis whitespace-nowrap w-100 overflow-hidden font-medium inline-block ${cancelOrderClass}`
        }}
      />
      {isOrderInCancelledState && (
        <Text
          {...{
            className: 'flex text-brand cancelled-order',
            content: ORDER_STATUS.CANCELLED.label
          }}
        />
      )}
    </div>

    <div className='flex gap-2 self-baseline items-center relative -top-[12px] -right-[12px]'>
      <Text
        {...{
          content: status,
          className: `${cartStatusClass} px-2 py-1 rounded relative z-20 text-xs`
        }}
      />

      <TextWithIcon
        {...{
          className:
            'items-center px-2 py-1 curve-shadow rounded-tr rounded-bl relative z-20',
          icon: 'cart-red.svg',
          iconHeight: 16,
          iconWidth: 16,
          label: parseNumberedLabel(cartNumber),
          labelStyle: ' text-nero text-sm relative z-20'
        }}
      />
    </div>
  </div>
);

const CartCardHeaderMobile = ({
  cancelOrderClass,
  cartCardTitle,
  cartNumber,
  cartStatusClass,
  isOrderInCancelledState,
  status
}) => (
  <div
    className={`flex flex-col md:flex-row gap-2  md:justify-between text-sm op ${cancelOrderClass}`}
  >
    <div className='flex flex-col md:self-center'>
      <Text
        {...{
          className: `text-lg ${cancelOrderClass}`,
          content: cartCardTitle
        }}
      />
      {isOrderInCancelledState && (
        <Text
          {...{
            className: `flex text-brand cancelled-order`,
            content: ORDER_STATUS.CANCELLED.label
          }}
        />
      )}
    </div>
    <div className='flex gap-4'>
      <TextKeyValuePair
        {...{
          className: '',
          label: `No. ${cartNumber}`,
          labelClass:
            'flex px-4 py-2 bg-light-red text-brand text-xs rounded-lg self-center h-8`',
          value: status,
          valueClassName: `flex px-4 py-2 ${cartStatusClass} text-xs rounded-lg self-center h-8`
        }}
      />
    </div>
  </div>
);

const CartCardHeaderMap = {
  [DEVICE_SURFACE.MOBILE]: CartCardHeaderMobile,
  [DEVICE_SURFACE.WEB]: CartCardHeaderWeb
};

const CartCardHeader = ({ cartName, cartNumber, cartOrder, status }) => {
  const surface = GetUiDeviceSurface();
  const CartHeader = CartCardHeaderMap[surface];

  const cartCardTitle = cartName || 'NA';
  const isCartInOrderState = status === CART_STATUS.ORDER.value;
  const isOrderInCancelledState =
    isCartInOrderState &&
    cartOrder.order.status === ORDER_STATUS.CANCELLED.value;
  const cartStatusClass = CART_STATUS[status.toUpperCase()].className;
  const cancelOrderClass = isOrderInCancelledState ? 'cancelled' : '';

  return (
    <CartHeader
      {...{
        cancelOrderClass,
        cartCardTitle,
        cartNumber,
        cartStatusClass,
        isOrderInCancelledState,
        status
      }}
    />
  );
};

export default CartCardHeader;
