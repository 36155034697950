import { Button } from '@/components/atomic/nuclei';
import { VIEW_EDIT_MODE } from '@/config/cart';
import { staticMediaStoreBaseURL } from '@/config/common';

const CartItemDetailCTA = ({
  discardChanges,
  handleSubmit,
  isCartEditable,
  isCartItemInViewMode,
  setCartItemViewEditMode,
  updateCartItemDetail
}) => (
  <div className='flex justify-between gap-4'>
    <div className='flex gap-4'>
      <Button
        {...{
          className: 'bg-gray py-2 h-9 text-sm font-medium px-6 rounded-lg',
          label: isCartItemInViewMode ? 'Close' : 'Cancel',
          onClick: discardChanges
        }}
      />
      {isCartEditable && (
        <Button
          {...{
            className: `${isCartItemInViewMode ? 'pl-4 pr-8' : 'px-6'} bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white py-2 rounded-lg text-sm font-medium`,
            iconHeight: 16,
            iconPosition: isCartItemInViewMode ? 'Left' : null,
            iconURL: `${staticMediaStoreBaseURL}/icons/edit-icon-white.svg`,
            iconWidth: 16,
            label: isCartItemInViewMode ? 'Edit' : 'Save',
            onClick: isCartItemInViewMode
              ? () => setCartItemViewEditMode(VIEW_EDIT_MODE.EDIT)
              : handleSubmit(updateCartItemDetail),
            width: 'w-20'
          }}
        />
      )}
    </div>
  </div>
);

export default CartItemDetailCTA;
