import { PropTypes } from 'prop-types';

const Radio = ({
  checked,
  dbName,
  direction = 'left',
  disabled = false,
  label,
  labelClass = 'text-sm font-medium',
  name = 'radio',
  radioClass,
  register,
  value
}) => (
  <label
    className={`radio-container ${radioClass} w-full justify-between relative flex  ${
      direction === 'left' ? 'flex-row' : 'flex-row-reverse'
    }`}
  >
    <div
      className={`relative top-0.5 ${
        direction === 'left' ? 'left-0' : '-left-4'
      }`}
    >
      <input
        {...{
          ...register(dbName),
          checked,
          className: 'self-center flex',
          disabled,
          name,
          type: 'radio',
          value
        }}
      />
      <span className='radiomark'></span>
    </div>
    {label && <span className={labelClass}>{label}</span>}
  </label>
);

export default Radio;

Radio.propTypes = {
  checked: PropTypes.bool,
  dbName: PropTypes.string,
  label: PropTypes.string,
  radioClass: PropTypes.string,
  register: PropTypes.func,
  value: PropTypes.any
};
