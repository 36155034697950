import Image from 'next/image';
import { useRef, useState } from 'react';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import useOnClickOutside from '@/hooks/useOnClickOutside';

const ScopeSearchAttributeModal = ({
  searchableAttribute,
  searchAttributeLabelMap,
  selectedAttributeLabel,
  setSearchableAttribute
}) => {
  const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false);

  const searchAttributeModalRef = useRef(null);

  const handleOnChange = (value) => {
    setSearchableAttribute(value);
    setIsSearchDropdownOpen(false);
  };

  useOnClickOutside(searchAttributeModalRef, () =>
    setIsSearchDropdownOpen(false)
  );

  return (
    <div
      className={`flex h-14 rounded-l-full px-2 bg-brand-gradient focus:outline-none text-white search-type-select cursor-pointer`}
      onClick={() => setIsSearchDropdownOpen(!isSearchDropdownOpen)}
      ref={searchAttributeModalRef}
    >
      <div className='flex w-40 gap-3 justify-between self-center'>
        <Text
          {...{
            className: 'flex-1 truncate pl-2 text-base text-center',
            content: selectedAttributeLabel
          }}
        />
        <div className='w-4 self-center flex justify-end'>
          <Image
            {...{
              alt: 'select-search-attribute',
              className: `transform ${isSearchDropdownOpen ? 'rotate-180' : ''}`,
              height: 0,
              src: `${staticMediaStoreBaseURL}/icons/down-angle-white.svg`,
              style: { height: 12, width: 12 },
              width: 0
            }}
          />
        </div>
      </div>
      {isSearchDropdownOpen && (
        <div className='fade-in w-60 border bg-white shadow-sm border-platinum absolute z-[2] mt-16 search-filter-modal rounded-lg'>
          <ul className='flex flex-col'>
            {Object.values(searchAttributeLabelMap).map(
              ({ esDocKey, key, label }, id) => (
                <li
                  className={`relative block searchkit-filter-menu-item cursor-pointer text-left hover:bg-brand-gradient hover:!text-white text-dim-gray px-4 py-2`}
                  key={`${key}_${id}`}
                  onClick={() => handleOnChange(esDocKey)}
                >
                  <label className='cursor-pointer'>
                    <input
                      {...{
                        checked: searchableAttribute === esDocKey,
                        className: 'w-[0.1px] h-[0.1px] absolute left-full',
                        name: 'searchable attribute option',
                        readOnly: true,
                        type: 'radio',
                        value: key
                      }}
                    />
                    <Text
                      {...{
                        className: 'text-base font-medium pl-2 self-center',
                        content: label,
                        HtmlTag: 'span'
                      }}
                    />
                  </label>
                </li>
              )
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ScopeSearchAttributeModal;
