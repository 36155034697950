import moment from 'moment';

export const convertToDateFormat = (date, format) =>
  moment.utc(date).format(format); // TODO: check if we should use utc here

export const formatTimeToAmPm = (time) =>
  time && moment(time, 'HH:mm').format('hh:mm A');

export const formatDateToHaflaStandardFormat = (date) =>
  date && moment(date).format('DD/MM/YYYY');

export const convertToStandardDateFormat = (date) =>
  convertToDateFormat(date, 'Do MMMM YYYY');

export const convertToShortMonthDateFormat = (date) =>
  convertToDateFormat(date, 'Do MMM YYYY');

export const convertToDateTimeString = ({ date, time }) => {
  const dateStr = date ? convertToShortMonthDateFormat(date) : '';
  const timeStr = time ? formatTimeToAmPm(time) : '';
  return [dateStr, timeStr].filter((str) => str).join(', ');
};

export const getFormattedDate = ({ date, format }) =>
  moment(date).format(format);

export const subtractDaysFromDate = ({ date, daysToSubtract }) =>
  date && moment(date).subtract(daysToSubtract, 'days');

export const parseDateWithMoment = (date) => moment(date);

export const convertToMonthYearFormat = ({ dateTimeString }) =>
  moment(dateTimeString).format('MMM YYYY');

export const isDateBeforeToday = ({ date }) =>
  moment(date).isBefore(moment(), 'day');
