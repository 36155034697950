import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';

import { Button, Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { PAGE_NAME, getPageURL } from '@/services/plannerPortal.service';

const ActionButton = ({ actionLink }) => (
  <Link
    href={actionLink}
    target='_blank'
  >
    <Button
      {...{
        className: 'text-white bg-brand-gradient rounded-full px-4 py-1',
        iconGroupClassName: 'gap-1',
        iconHeight: 16,
        iconPosition: 'Left',
        iconURL: `${staticMediaStoreBaseURL}/icons/view-icon.svg`,
        iconWidth: 16,
        label: 'View Cart'
      }}
    />
  </Link>
);

const AddItemToCartInfoBand = ({
  activePlannerCart,
  onClickFinishCTA,
  setIsInfoBandEnabled
}) => {
  const actionLink = getPageURL({
    pageName: PAGE_NAME.PLANNER_CART.label,
    pathParams: { cartId: activePlannerCart.id }
  });
  const message = `Adding products to cart #${activePlannerCart.cartNumber}`;

  return (
    <div
      className={`fixed bottom-10 add-to-cart-toast z-[1000] flex gap-4 right-[2%]`}
    >
      <div
        className={`bg-black text-white text-sm font-medium flex items-center gap-4 justify-left py-3
      rounded-full px-4`}
      >
        <div className='self-center flex'>
          <Image
            alt='ToasterIcon'
            height={0}
            src={`${staticMediaStoreBaseURL}/icons/cart-red.svg`}
            style={{ height: 16, width: 16 }}
            width={0}
          />
        </div>
        <Text
          className='self-center'
          content={message}
        />
        <Text
          {...{
            className: 'text-brand font-medium cursor-pointer hover:text-white',
            content: 'Finish Cart',
            onClick: () => {
              onClickFinishCTA();
            }
          }}
        />
        <ActionButton {...{ actionLink }} />
      </div>
      <Image
        alt='close'
        className='cursor-pointer'
        height={0}
        onClick={() => setIsInfoBandEnabled(false)}
        src={`${staticMediaStoreBaseURL}/icons/close-black-bg.svg`}
        style={{ height: 50, width: 50 }}
        width={0}
      />
    </div>
  );
};

const AddItemToCartInfoBubble = ({ cartItemCount, setIsInfoBandEnabled }) => (
  <div className='fixed bottom-10 right-[2%] z-20'>
    <div
      onClick={() => setIsInfoBandEnabled(true)}
      className='flex justify-center items-center relative w-14 h-14 rounded-full bg-brand-gradient cursor-pointer'
    >
      <Image
        alt='close'
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/cart.svg`}
        style={{ height: 24, width: 24 }}
        width={0}
      />
      <Text
        className='text-brand flex justify-center items-center text-xs font-medium absolute top-3 left-7 w-5 h-5 bg-white rounded-full'
        content={cartItemCount}
        HtmlTag='span'
      />
    </div>
  </div>
);

const AddToCartToaster = ({ activePlannerCart, onClickFinishCTA }) => {
  const [isInfoBandEnabled, setIsInfoBandEnabled] = useState(true);

  return isInfoBandEnabled ? (
    <AddItemToCartInfoBand
      {...{
        activePlannerCart,
        onClickFinishCTA,
        setIsInfoBandEnabled
      }}
    />
  ) : (
    <AddItemToCartInfoBubble
      {...{
        cartItemCount: activePlannerCart.cartItemCount,
        setIsInfoBandEnabled
      }}
    />
  );
};

export default AddToCartToaster;
